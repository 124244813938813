<template>
  <div class="mt-1">
    <!-- Document icons -->
    <!-- <div class="d-flex flex-column">
          <p>Document icons</p>
          <div v-for="(icon, index) in documentIcons" :key="index" class="d-flex pa-1" >
              <div class="mx-2">
                  <v-icon :color="icon.color">{{icon.icon}}</v-icon>
                  <span>{{icon.docLocation}}</span>
              </div>
          </div>
      </div> -->
    <v-layout v-if="compact">
      <gat-compact-field v-for="doc in documents" :key="doc.ID" :value="doc.FILENAME" size="m">
        <span class="filelink">
          <v-icon :color="getMdiFileIcon(doc.FILENAME).color">{{ getMdiFileIcon(doc.FILENAME).icon }}</v-icon>
          <a class="filename filelink gridLink" @click="download(doc.ID, doc.FILENAME)">{{ doc.FILENAME }}</a>
        </span>
      </gat-compact-field>
    </v-layout>
    <div v-else>
      <v-layout wrap v-if="userCanAddDocuments">
        <gat-flex size="xxl" class="mb-1">
          <document-uploader
            :disabled="disabled"
            :documents="documents"
            :location="location"
            :parent-id="parentId"
            @get-documents="($event) => getDocuments($event)"
            @file-selected="($event) => $emit('files-selected', $event)" />
        </gat-flex>
      </v-layout>
      <!-- $vuetify.breakpoint.xsOnly :noPadding="$vuetify.breakpoint.xsOnly" -->
      <gat-grid
        v-resize="onResize"
        gridName="documentCompGrid"
        dense
        noPadding
        :items="documents"
        :columns="columns"
        :title="getTitle"
        keyField="ID"
        :hide-empty-columns="true"
        :class="getGridClass"
        :rowMenu="rowMenu"
        reloadBtn
        :loading="loading"
        :fixedheader="true"
        :height="location == 'portcall' ? 'calc(100vh - 200px)' : 'auto'"
        :selectable="location == 'portcall'"
        keyName="ID"
        :selectedKeys="selectedDocumentIds"
        @selectionChanged="selectedDocumentIds = $event"
        @rowMenuClicked="rowMenuClicked"
        :orgSortBy="[{ header: 'Date', desc: true }]"
        @reload-clicked="[getDocuments(parentId), getTemplates()]">
        <template v-if="props.column.custom" slot="cell" slot-scope="props">
          <div v-if="props.column.field == 'DOCUMENT_ORIGIN'">
            <v-tooltip bottom v-if="props.value !== 'portcall'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" :color="getDocumentOrigin(props.value).color">{{
                  getDocumentOrigin(props.value).icon
                }}</v-icon>
              </template>
              {{ getDocumentOrigin(props.value).hint }}
            </v-tooltip>
          </div>
          <grid-label v-if="props.value && props.column.field == 'LABEL_ID'" :labelId="props.row.LABEL_ID" />

          <div v-if="props.column.field == 'StoredInDb'">
            <v-icon v-if="+props.value === 1" color="green lighten-1">mdi-database</v-icon>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-folder-open-outline</v-icon>
              </template>
              This document is stored on disk. <br />
              Store it in the database from the documents tab in GS-Classic <br />
              to make this document available for download in the web module
            </v-tooltip>
          </div>

          <div
            v-if="props.value && props.column.field === 'TITLE'"
            class="d-inline-flex align-center justify-space-between flex-grow-1"
            style="width: 100%">
            <v-tooltip bottom v-if="props.row.StoredInDb">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex align-center"
                  @click="download(props.row.ID, props.row.FILENAME)">
                  <v-icon :color="getMdiFileIcon(props.row.FILENAME).color">{{
                    getMdiFileIcon(props.row.FILENAME).icon
                  }}</v-icon>
                  <div class="ml-2 gridLink">
                    {{ props.value }}
                  </div>
                </div>
              </template>
              <div class="d-flex align-center">
                <span>{{ props.row.FILENAME }}</span>
              </div>
            </v-tooltip>
            <div v-else>
              <div class="d-flex align-center">
                <v-icon>{{ getMdiFileIcon(props.row.FILENAME).icon }}</v-icon>
                <span class="ml-2">{{ props.row.FILENAME }}</span>
              </div>
            </div>
          </div>

          <div v-if="props.column.field === 'SIZE' && props.value">
            <div class="d-flex nowrap" style="width: 100%">{{ props.value }} kb</div>
          </div>

          <div v-if="props.column.field == 'WEB_ACCESS' && props.value" class="d-flex flex-nowrap">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip small v-bind="attrs" v-on="on" @click="showProperties(props.row)">{{
                  getAccessGroupNames(props.value).displayName
                }}</v-chip>
              </template>
              <div v-for="accesGroups in getAccessGroupNames(props.value).content" :key="accesGroups">
                {{ accesGroups }}
              </div>
            </v-tooltip>
          </div>

          <div
            v-else-if="
              (applicationStore.user.internal && props.column.field == 'CONTEXT_MENU') ||
              ($vuetify.breakpoint.xs && props.column.field == 'CONTEXT_MENU')
            "
            class="d-flex align-center justify-center">
            <v-btn icon @click.stop="showContextMenu($event, props.row.ID)">
              <v-icon color="primary">mdi-dots-vertical</v-icon>
            </v-btn>
          </div>

          <gat-edit
            class="selectbox"
            :disabled="!props.row.StoredInDb"
            v-if="props.column.field == 'selected'"
            v-model="props.row.selected"
            checkbox />

          <div class="filelink d-flex align-center" v-if="props.column.field == 'FILENAME' && props.row.StoredInDb">
            <v-icon :color="getMdiFileIcon(props.row.FILENAME).color">{{
              getMdiFileIcon(props.row.FILENAME).icon
            }}</v-icon>
            <a class="filelink gridLink ml-1" @click="download(props.row.ID, props.row.FILENAME)">{{
              props.row.FILENAME
            }}</a>
          </div>
          <span v-if="props.column.field == 'FILENAME' && !props.row.StoredInDb">{{ props.row.FILENAME }}</span>
        </template>
        <template slot="btns">
          <!-- <div class="d-flex pb-2 flex-grow-1">
              <div class="d-flex ml-auto"> -->
          <wm-button-group
            class="right"
            :buttons="wmButtonGroup"
            @deleteClick="showDeleteDocumentsDialog = true"
            @createEmailClick="(val) => createEmailClick(val)"
            @documentCreated="getDocumentsWhenCreated" />
        </template>
      </gat-grid>
    </div>
    <document-properties
      :showForm="showPropertiesForm"
      v-model="document"
      :webAccessGroups="webAccessGroups"
      @close="propertiesFormClosed"></document-properties>

    <new-document
      :showForm="showNewDocument"
      @close="newDocumentFormClosed"
      :readonly="!applicationStore.userRight('DOC.EDIT_AND_NEW')" />
    <v-dialog v-model="showDeleteDocumentsDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="title">Delete document{{ selectedDocumentIds.length > 1 ? 's' : '' }}</v-card-title>

        <v-card-text class="text-center"
          >Are you sure you want to delete
          {{
            selectedDocumentIds.length > 1 ? selectedDocumentIds.length + ' documents?' : 'this document?'
          }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDeleteDocumentsDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="deleteSelectedDocuments(selectedDocumentIds)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <email-generator
      :portcallId="callId"
      :location="location"
      :guid="emailTemplateGuid"
      @documentCreated="goToEmailTemplate($event)"
      noAction />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete, apiGetFile } from '@/store/api';
import GridLabel from '@/app-components/GridLabel.vue';
import EmailGenerator from '@/app-components/EmailGenerator.vue';
import { isValidEmail } from '@/modules/email/emailFunctions';
import { useConstantsStore } from '@/store/constantsStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useAppStatusStore } from '@/store/appStatusStore';
import { useHelperStore } from '@/store/helperStore';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import DocumentHelper from '@/common/DocumentHelper';
import DocumentProperties from './DocumentProperties.vue';
import NewDocument from './NewDocument.vue';
import DocumentUploader from './documents/components/document-uploader/DocumentUploader.vue';
import SednaApiService from '../services/api/external/sednaAPI';
import { getDocumentTemplates } from '../services/api/api-calls/getDocumentTemplates';

export default {
  name: 'documents',
  props: ['docTypeFilter', 'location', 'parentId', 'embedded', 'compact', 'applicationStatus', 'disabled', 'callId'],
  components: { DocumentProperties, NewDocument, /* NewDocumentMenu, */ GridLabel, EmailGenerator, DocumentUploader },
  setup() {
    const applicationStore = useApplicationStore();
    const constantsStore = useConstantsStore();
    const portcallStore = usePortcallStore();
    const appStatusStore = useAppStatusStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      constantsStore,
      applicationStore,
      portcallStore,
      appStatusStore,
    };
  },
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      documents: [],
      uploading: false,
      loading: false,
      document: null,
      showPropertiesForm: false,
      showNewDocument: false,
      selectedDocumentIds: [],
      showDeleteDocumentsDialog: false,
      timestamp: '',
      userCanDeleteSelectedDocuments: false,
      emailTemplates: [],
      emailTemplateGuid: undefined,
      emailAttachments: [],
    };
  },

  created() {},
  deactivated() {
    if (
      this.$route.name != 'vesselDetails' &&
      this.$route.name != 'vesselDocs' &&
      this.$route.name != 'clientDetails' &&
      this.$route.name != 'clientContacts'
    ) {
      this.applicationStore.setTraversableList([]);
      this.applicationStore.setTraversableListCounter(false);
    }
  },

  computed: {
    userCanGenerateDoc() {
      const isInternal = this.applicationStore.user.internal;
      if (isInternal) {
        return this.applicationStore.userRight('DOC.NEW_AND_EDIT');
      }
      const externalRights = this.applicationStore.userRight('DOC.GENERATE_DOCUMENT');
      return externalRights;
    },
    userCanAddDocumentsToEmail() {
      if (this.selectedDocumentIds.length === 0) {
        return false;
      }
      for (let i = 0; i < this.selectedDocumentIds.length; i++) {
        const id = this.selectedDocumentIds[i];
        const doc = this.documents.find((item) => item.ID === id);
        if (doc && doc.StoredInDb !== 1) {
          return false;
        }
      }
      return true;
    },
    appstatus() {
      if (this.location === 'client') {
        return this.applicationStatus;
      }
      const applicationStatus = this.appStatusStore.getAppStatus;
      return { ...this.applicationStatus, ...applicationStatus };
    },
    agencyIdSelected() {
      return this.applicationStore.agencyIdSelected;
    },
    agencyId() {
      if (this.callId) {
        return this.portcallStore.callDetails.SETUP_ID;
      }
      return this.agencyIdSelected;
    },
    wmButtonGroup() {
      const result = [];
      if (this.userCanGenerateDoc) {
        result.push({
          btnType: 'CREATE_DOC',
          docType: this.docTypeFilter,
          agencyId: this.agencyId,
          location: this.location,
          foreignKey: this.callId,
          applicationStatus: this.appstatus,
          disabled: !this.userCanGenerateDoc, // this.selectedItemKeys.length === 0 ||
          mainAction: true,
          showOnPhone: this.windowSize.x >= 392,
          // menuItems: [{ caption: 'Hei på deg', menuItems: [{ caption: 'YEP YEP' }] }],
        });
        if (
          this.applicationStore.globalSettings.SGL_WM_EMAIL_PROVIDER !== 0 &&
          this.applicationStore.userRight('EM.EDIT')
        ) {
          result.push({
            btnType: 'CUSTOM',
            caption: 'Send with email',
            icon: 'mdi-email',
            menuItems: this.emailTemplateItems,
            disabled: !this.userCanAddDocumentsToEmail,
          });
        }
        if (
          !this.$vuetify.breakpoint.xs && this.applicationStore.user.internal
            ? true
            : this.applicationStore.userRight('DOC.DELETE')
        ) {
          result.push({ btnType: 'DELETE', disabled: !this.userCanDeleteDocuments });
        }
      }
      return result;
    },
    columns() {
      const result = [];
      // result.push({"header":"Select","field":"selected","custom":true});
      if (!this.embedded) {
        result.push({
          header: '',
          field: 'DOCUMENT_ORIGIN',
          hideOnPhone: true,
          custom: true,
          width: '1%',
          class: 'px-0',
        });
      }
      result.push({ header: 'Label', field: 'LABEL_ID', custom: true, width: '1%', class: 'px-2' });
      result.push({
        header: 'Added',
        field: 'ADDED_DATE',
        hideOnPhone: true,
        dataType: 'datetime',
        width: '1%',
        class: 'px-2',
      });
      result.push({ header: '', field: 'StoredInDb', width: '1%', class: 'px-1', hideOnPhone: true, custom: true });
      result.push({ header: 'Title', field: 'TITLE', hideOnPhone: true, custom: true, class: 'px-4' });
      if (this.$vuetify.breakpoint.xsOnly) {
        result.push({ header: 'File', field: 'FILENAME', custom: true });
      }
      result.push({
        header: 'File date',
        field: 'CREATED_DATE',
        hideOnPhone: true,
        dataType: 'datetime',
        width: '1%',
        class: 'px-2',
      });
      result.push({ header: 'Uploaded by', field: 'UPLOADED_BY', hideOnPhone: true, width: '1%', class: 'px-2' });
      result.push({
        header: 'Size',
        field: 'SIZE',
        hideOnPhone: true,
        width: '1%',
        class: 'px-2',
        custom: true,
        calculated: (rowData) => `${rowData.SIZE} kb`,
      });
      if (this.applicationStore.user.internal) {
        result.push({
          header: 'Web access',
          field: 'WEB_ACCESS',
          hideOnPhone: true,
          custom: true,
          width: '1%',
          class: 'px-2',
        });
      }
      result.push({
        header: '',
        field: 'CONTEXT_MENU',
        custom: true,
        hideOnPhone: false,
        ignoreOnCopy: true,
        width: '1%',
        class: 'px-0',
      });
      return result;
    },

    documentIcons() {
      return this.constantsStore.documentIcons;
    },
    getTitle() {
      if (this.embedded) {
        return '';
      }
      return 'Documents';
    },
    getGridClass() {
      /* if(this.embedded){
                return "elevation-0"
               } */
      return 'elevation-0';
    },
    // contextMenu
    rowMenu() {
      const result = [];
      if (this.applicationStore.user.internal) {
        result.push({
          name: 'change title',
          caption: 'Properties',
          icon: 'mdi-playlist-edit',
          enabled: this.applicationStore.userRight('DOC.NEW_AND_EDIT'),
        });
        result.push({
          name: 'delete document',
          caption: 'Delete document',
          icon: 'delete',
          enabled: this.applicationStore.userRight('DOC.NEW_AND_EDIT') && this.allowDeleteDocument,
        });
        result.push({
          name: 'remove label',
          caption: 'Remove label',
          icon: 'mdi-label-off',
          enabled: this.applicationStore.userRight('DOC.NEW_AND_EDIT'),
          separatorBelow: true,
        });
        let subHeader = 'Labels';
        for (let index = 0; index < this.helperStore.documentLabels.length; index++) {
          const label = this.helperStore.documentLabels[index];
          let locationFilter = null;
          switch (this.location) {
            case 'portcall':
              locationFilter = 0;
              break;
            case 'client':
              locationFilter = 1;
              break;
            case 'vessel':
              locationFilter = 2;
              break;
            case 'proforma': // not yet implemented
              locationFilter = 3;
              break;
            case 'purchaseInvoice': // not yet implemented
              locationFilter = 4;
              break;
            case 'expense':
              locationFilter = 5;
              break;
            case 'service':
              locationFilter = 7;
              break;
            case 'crewchangeperson':
              locationFilter = 10;
              break;
            case 'crewChangeTask':
              locationFilter = 11;
              break;
            default: // all labels.
              locationFilter = -1;
          }

          if (
            (label.DLA_TYPE_EMAIL != 1 && label.DLA_LOCATION_FILTER == null) ||
            label.DLA_LOCATION_FILTER == locationFilter
          ) {
            /** Returns false i */
            const showLabelByFilter =
              label.agencyFilter.length === 0
                ? true
                : label.agencyFilter.map((item) => item.AF_AGENCY_ID).includes(this.agencyId);
            if (showLabelByFilter) {
              result.push({
                name: 'setLabel',
                caption: label.DLA_LABEL,
                icon: 'label',
                enabled: this.applicationStore.userRight('DOC.NEW_AND_EDIT'),
                subHeader,
                label,
                iconColor: `#${this.delphiColorToHex(label.DLA_COLOR_BACKGROUND)}`,
              });
            }
            subHeader = null;
          }
        }
      } else if (this.applicationStore.userRight('DOC.DELETE'))
        result.push({
          name: 'delete document',
          caption: 'Delete document',
          icon: 'delete',
          enabled: this.allowDeleteDocument,
        });

      return result;
    },

    rules() {
      return [
        (files) => {
          for (let index = 0; index < files.length; index++) {
            const file = files[index];
            if (file.size > 10000000) {
              return 'File size must be less than 10 MB!';
            }
          }

          return true;
        },
        (files) => {
          if (files.length > 9) {
            return 'Max 10 files can be uploaded at once';
          }

          return true;
        },
      ];
    },

    webAccessGroups() {
      return this.helperStore.webAccessGroups;
    },

    userCanAddDocuments() {
      const { isInternal } = this;
      let externalRights = false;
      let internalRights = false;
      let result = false;
      if (this.location === 'service') {
        externalRights = this.applicationStore.userRight('SER.EDIT') && this.applicationStore.userRight('DOC.UPLOAD');
        internalRights =
          this.applicationStore.userRight('SER.EDIT') && this.applicationStore.userRight('DOC.NEW_AND_EDIT');
        result = isInternal ? internalRights : externalRights;
        return result;
      }
      if (this.location === 'expense') {
        externalRights = this.applicationStore.userRight('EX.EDIT') && this.applicationStore.userRight('DOC.UPLOAD');
        internalRights =
          this.applicationStore.userRight('EX.EDIT') && this.applicationStore.userRight('DOC.NEW_AND_EDIT');
        result = isInternal ? internalRights : externalRights;
        return result;
      }
      externalRights = this.applicationStore.userRight('DOC.UPLOAD');
      internalRights = this.applicationStore.userRight('DOC.NEW_AND_EDIT');
      result = isInternal ? internalRights : externalRights;
      return result;
    },

    isInternal() {
      return this.applicationStore.user.internal;
    },

    deleteDocumentUserRight() {
      const { isInternal } = this;
      const internalUserRights = this.applicationStore.userRight('DOC.DELETE'); // Internal users
      const externalUserRights = this.applicationStore.userRight('DOC.DELETE'); // External users
      return isInternal ? internalUserRights : externalUserRights;
    },

    userCanDeleteDocuments() {
      return this.deleteDocumentUserRight && this.userCanDeleteSelectedDocuments;
    },
    emailTemplateItems() {
      const templates = [...this.emailTemplates];
      let result = [];
      result.push({
        caption: 'Send with empty email',
        eventName: 'createEmailClick',
        icon: 'mdi-email',
        ID: -1,
      });
      const ignoreGroupMinCount = this.applicationStore.hasFeatureCode('IGNORE_DOCUMENT_GROUPING_MIN_COUNT');
      if (Array.isArray(templates) && templates.length > 0) {
        if (ignoreGroupMinCount ? true : templates.length > 10) {
          // Gruppere på labels
          const templateResultGrouped = this.helperStore.emailTemplatesGroupedByLabels(templates, false);
          result = [...result, ...templateResultGrouped];
        } else {
          const templateResult = templates.map((item) => ({
            useDividerBefore: result.length === 1,
            caption: item.TITLE,
            eventName: 'createEmailClick',
            icon: 'mdi-file-outline',
            ID: item.ID,
          }));
          result = [...result, ...templateResult];
        }
      }
      return result;
    },
  },

  mounted() {
    this.onResize();
  },
  watch: {
    parentId: {
      handler(newId, oldId) {
        if (newId != oldId) {
          // covnsole.log(oldId+'->'+newId);
          this.getDocuments(newId);
        }
      },
      immediate: true,
    },

    selectedDocumentIds(selectedDocumentsIds) {
      if (this.deleteDocumentUserRight && selectedDocumentsIds.length > 0) {
        const docs = this.documents;
        for (let i = 0; selectedDocumentsIds.length > i; i++) {
          const idx = docs.findIndex((item) => item.ID === selectedDocumentsIds[i]);
          if (!this.allowDeleteDocument(docs[idx])) {
            // eslint-disable-next-line no-return-assign
            return (this.userCanDeleteSelectedDocuments = false);
          }
        }
        // eslint-disable-next-line no-return-assign
        return (this.userCanDeleteSelectedDocuments = true); // can delete if all criteria are met.
      }
      // eslint-disable-next-line no-return-assign
      return (this.userCanDeleteSelectedDocuments = false);
    },
  },

  methods: {
    getTemplates() {
      getDocumentTemplates();
    },
    download(docId, fileName) {
      DocumentHelper.download(docId, fileName);
    },
    getMdiFileIcon(fileName) {
      return DocumentHelper.getMdiFileIcon(fileName);
    },
    async createEmailClick(item) {
      // eslint-disable-next-line prefer-destructuring
      const sednaApi = new SednaApiService(this.agencyId);
      const { isSednaIntegration } = sednaApi;
      const templateId = item.ID;
      const promises = [];
      const attachmentsFromDocuments = [];
      this.selectedDocumentIds.forEach((docId) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const fileName = this.documents.find((item) => item.ID === docId).FILENAME;
        if (fileName) {
          promises.push(
            apiGetFile(`document/download/${docId}`, fileName, false).then((result) => {
              const file = new File([result], fileName, { type: 'text/plain', lastModified: new Date().getTime() });
              attachmentsFromDocuments.push(file);
            })
          );
        }
      });

      await Promise.allSettled(promises);
      this.emailAttachments = attachmentsFromDocuments;

      if (templateId === -1) {
        // create new email - empty
        if (isSednaIntegration) {
          const sednaJobId = this.portcallStore.callDetails.SEDNA_JOB_ID;
          if (sednaJobId) {
            const sednaUrl = await sednaApi.composeNewSednaEmail(sednaJobId, attachmentsFromDocuments);
            if (sednaUrl) {
              window.open(sednaUrl, '_blank');
            }
          } else {
            // TODO-sedna: const locationId = this.location === 'vessel' ? vesselId : this.location === 'client' ? clientId : portcallId (this.callId);
            const sednaJbId = await sednaApi.createSednaJobRef(this.callId, this.location);
            if (sednaJbId) {
              const sednaUrl = await sednaApi.composeNewSednaEmail(sednaJbId, attachmentsFromDocuments);
              if (sednaUrl) {
                window.open(sednaUrl, '_blank');
              }
            }
          }
        } else {
          const result = await apiGet('mail/new-email');
          if (result && result.payload) {
            const emailId = result.payload.ID;
            const mailNo = result.payload.MAIL_NO;
            // We don't need to store these attachments any longer.
            this.resetEmailAttachments();
            this.$router.push({
              name: 'prortcallEmailNew',
              params: {
                callId: this.callId,
                emailId,
                to: [],
                bcc: [],
                cc: [],
                mailNo,
                attachmentsFromDocuments,
                saveDraftOnLoad: true,
                content: ' ',
              },
            });
            this.selectedDocumentIds = [];
          }
        }
      } else if (this.callId && templateId > 0) {
        // create new email - using template - only for portcall
        const guid = GlobalHelperFunctions.GUID();
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const templateId = item.ID;
        const location = 'portcall';
        const foreignKey = this.callId;
        const appStatus = JSON.stringify(this.appstatus);
        const param = { guid, templateId, location, foreignKey, appStatus, isEmail: 1 };
        apiPut('document/generate/', param).then((result) => {
          if (result.resultCategory === 0 && result.resultType === 0) {
            this.emailTemplateGuid = guid;
          }
        });
      }
    },
    resetEmailAttachments() {
      this.emailAttachments = [];
    },
    async getEmailRecipients(recipientsArray) {
      const result = [];
      const error = [];
      if (Array.isArray(recipientsArray) && recipientsArray.length > 0) {
        const promises = [];
        recipientsArray.forEach((item) => {
          if (isValidEmail(item.email)) {
            promises.push(
              apiGet(`portcall/emailRecipients/addManual/${item.email}`).then((res) => {
                if (Array.isArray(res) && res.length > 0) {
                  result.push(res[0]);
                } else {
                  result.push(item.email);
                }
              })
            );
          } else {
            error.push(`email ${item.email} is not a valid e-mail address`);
          }
        });
        await Promise.allSettled(promises);
      }
      return { result, error };
    },
    async goToEmailTemplate(item) {
      // when email is generated in the backend, documentCreated event is fired
      const id = item.ID;
      if (id) {
        const result = await apiGet(`mail/${id}`);
        const email = result;
        const toResult = await this.getEmailRecipients(email.TO);
        const ccResult = await this.getEmailRecipients(email.CC);
        const bccResult = await this.getEmailRecipients(email.BCC);
        const error = [...toResult.error, ...ccResult.error, ...bccResult.error];
        const sednaParams = {
          emailId: email.ID,
          mailNo: email.MAIL_NO,
          subject: email.SUBJECT,
          to: toResult.result,
          cc: ccResult.result,
          bcc: bccResult.result,
          content: email.MAIL_HTML,
          location: this.location,
          createdBy: this.applicationStore.user.userNameLong.toUpperCase(),
        };
        const sednaApi = new SednaApiService(this.agencyId);
        if (sednaApi.isSednaIntegration) {
          const sednaJobId = this.portcallStore.callDetails.SEDNA_JOB_ID;
          if (sednaJobId) {
            const sednaUrl = await sednaApi.composeNewSednaEmail(sednaJobId, this.emailAttachments, sednaParams);
            apiDelete(`mail/${email.ID}`).then(() => {
              this.$emit('refreshEmail', null);
              if (sednaUrl) {
                window.open(sednaUrl, '_blank');
              }
            });
          } else {
            // TODO-sedna: const locationId = this.location === 'vessel' ? vesselId : this.location === 'client' ? clientId : portcallId (this.callId);
            const sednaJbId = await sednaApi.createSednaJobRef(this.callId, this.location);
            if (sednaJbId) {
              const sednaUrl = await sednaApi.composeNewSednaEmail(sednaJbId, this.emailAttachments, sednaParams);
              apiDelete(`mail/${email.ID}`).then(() => {
                this.$emit('refreshEmail', null);
                if (sednaUrl) {
                  window.open(sednaUrl, '_blank');
                }
              });
            }
          }
        } else {
          const params = {
            ...sednaParams,
            portcallId: this.callId,
            // attachments: email?.MAIL_ATTACHMENTS || [],
            attachmentsFromDocuments: this.emailAttachments,
            onLoadError: error,
            isDraft: true,
            createdBy: this.applicationStore.user.userNameLong.toUpperCase(),
            saveDraftOnLoad: true,
          };
          this.$router.push({ name: 'prortcallEmailNew', params });
        }
      }
      this.resetEmailAttachments();
      this.selectedDocumentIds = [];
      this.isLoading = false;
      this.emailTemplateGuid = undefined;
    },

    getDocumentOrigin(docOrigin) {
      let result = '';
      const found = this.documentIcons.find((item) => item.docLocation === docOrigin);
      if (found) {
        result = found;
      }
      return result;
    },
    allowDeleteDocument(rowItem) {
      if (this.deleteDocumentUserRight) {
        const { isInternal } = this;
        if (isInternal) {
          return this.canDeleteDocumentTypes(rowItem.DOCUMENT_ORIGIN) && rowItem.StoredInDb === 1;
        }
        return (
          rowItem.UPLOADED_BY &&
          this.applicationStore.user.userNameLong.toUpperCase() === rowItem.UPLOADED_BY.toUpperCase() &&
          this.canDeleteDocumentTypes(rowItem.DOCUMENT_ORIGIN) &&
          rowItem.StoredInDb === 1
        );
      }
      return false;
    },
    canDeleteDocumentTypes(docOrigin) {
      switch (docOrigin) {
        case 'portcall':
          return true; // Kan slettes fordi disse uten tvil ligger rett på port callet
        case 'proforma':
          return false; // Usikker på denne, derfor åpner vi ikke for å kunne slette.
        case 'expense':
          return this.location === 'expense'; // Skal ikke kunne slettes fordi dokumentet tilhører en Expense (kan slettes fra expense-vinduet)
        case 'invoice':
          return false; // Skal ikke kunne slettes fordi dokumentet tilhører en Invoice.
        case 'enquiry':
          return false; // Litt usikker på denne, derfor lar vi vær å åpne for sletting
        case 'quay':
          return false; // Skal ikke kunne slettes fordi dokumentet tilhører en Quay og kan gjelde på tvers av mange anløp.
        case 'service':
          return this.location === 'service'; // Skal ikke kunne slettes fordi dokumentet tilhører en Service (kan slettes fra service vinduet)
        case 'prospects':
          return false; // Litt usikker på denne, tror dette er omtrent som en SOF (som kan slettes), men vi sperrer for sletting fordi jeg ikke vet hvordan dette funker.
        case 'client':
          return false; // Skal ikke kunne slettes fordi dokumentet tilhører en Client og kan gjelde på tvers av mange anløp.
        case 'vessel':
          return false; // Skal ikke kunne slettes fordi dokumentet tilhører et Vessel og kan gjelde på tvers av mange anløp.
        case 'crewChangePerson':
          return this.location === 'crewchangeperson'; // Skal ikke kunne slettes fordi dokumentet tilhører en Crew Change person (kan slettes fra Crew Change person-vinduet)
        case 'crewchangetask':
          return this.location === 'crewchangetask'; // Skal ikke kunne slettes fordi dokumentet tilhører en Crew Change task (kan slettes fra Crew Change task-vinduet)
        case 'unkown':
          return false;
        default:
          return true;
      }
      /*
              0 (portcall) Document.PORTCALL_ID is not null and Document.TYPE = 0  then 'portcall'
              2 (proforma) Document.PROFORMA_ID is not null or Document.TYPE = 2 then 'proforma'
              3 (billOfLading) Document.TYPE = 3 then 'billOfLading'
              4 (sof) Document.TYPE = 4 then 'sof'
              6 (generalDeclaration) Document.TYPE = 6 then 'generalDeclaration'
              7 (manifest) Document.TYPE = 7 then 'manifest'
              8 (visitorList) Document.TYPE = 8 then 'visitorList'
              9 (priorNotice) Document.TYPE = 9 then 'priorNotice'
              11 (expense) Document.EXPENSE_ID is not null or Document.TYPE = 11 then 'expense'
              12 (invoice) Document.IIN_ID is not null and Document.TYPE = 12 then 'invoice'
              12 (enquiry) Document.INQUERY_ID is not null or Document.TYPE = 12 then 'enquiry'
              13 (deliveries) Document.TYPE = 13 then 'deliveries'
              17 (quay) Document.QUAY_ID is not null or Document.TYPE = 17 then 'quay'
              18 (service) Document.SERVICE_ID is not null or Document.TYPE = 18 then 'service'
              19 (prospects) Document.TYPE = 19 then 'prospects'
              20 (client) Document.CLIENT_ID is not null or Document.TYPE = 20 then 'client'
              21 (vessel) Document.VESSEL_ID is not null or Document.TYPE = 21 then 'vessel'
              22 (attendance) Document.TYPE = 22 then 'attendance'
              23 (crewChangePerson) Document.CREWCHANGE_PERSON_ID is not null or Document.TYPE = 23 then 'crewChangePerson'
              24 (crewChangeTask) Document.CREWCHANGE_TASK_ID is not null or Document.TYPE = 24 then 'crewChangeTask'
              -- (checklist) Finnes ikke? Document.CHECKLIST_ID is not null then 'checklist'
              (unkown) else 'unkown' */
    },

    getAccessGroupNames(ids) {
      const result = {
        displayName: '',
        content: [],
      };
      if (ids) {
        const idArr = ids.split(',');
        idArr.forEach((id) => {
          const accesGrp = this.webAccessGroups.find((item) => item.ID == id);
          if (accesGrp && accesGrp.NAVN) {
            result.content.push(accesGrp.NAVN);
          }
        });
        if (result.content.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          result.displayName = result.content[0];
        }
        if (result.content.length > 1) {
          result.displayName += ` +${result.content.length - 1}`;
        }
      }
      return result;
    },

    deleteDocumentById(id) {
      apiDelete(`document/${id}/${this.location}`).then((result) => {
        if (result.resultCategory == 1 && result.resultType == 4) {
          const idx = this.documents.findIndex((item) => item.ID == id);
          if (idx >= 0) {
            this.documents.splice(idx, 1);
          }
          if (this.location == 'portcall') {
            // update the tabHasData property.
            this.portcallStore.setTabHasData({ tabName: 'DOCUMENT', value: this.documents.length });
          }
        }
      });
    },

    deleteSelectedDocuments(idArr) {
      const ids = idArr;
      ids.forEach((id) => {
        this.deleteDocumentById(id);
      });
      this.selectedDocumentIds = [];
      this.showDeleteDocumentsDialog = false;
    },

    getDocumentsWhenCreated() {
      if (this.parentId) this.getDocuments(this.parentId);
    },
    showContextMenu(event, rowItemId) {
      // used for the context button on mobile devices.
      this.gatComponentsMessageBus.$emit('gatgrid-rowmenu-show-context-menu', {
        event,
        rowItemId,
        gridName: 'documentCompGrid',
      });
    },
    newDocumentFormClosed() {
      this.showNewDocument = false;
    },

    getDocuments(parentId) {
      if (parentId) {
        this.documents = [];
        this.emailTemplates = [];
        this.loading = true;
        apiGet(`document/${this.location}/${parentId}`)
          .then((result) => {
            if (result && result.length > 0) {
              // eslint-disable-next-line array-callback-return
              result.map((item) => {
                // eslint-disable-next-line no-param-reassign
                item.selected = false;
                // eslint-disable-next-line no-param-reassign
                item.icon = 'test';
                // eslint-disable-next-line no-param-reassign
                item.CONTEXT_MENU = 'placeholder value';
              });
              this.documents = result;
              if (this.location == 'portcall') {
                // update the tabHasData property.
                this.portcallStore.setTabHasData({ tabName: 'DOCUMENT', value: result.length });
              }
            }
            let aId = this.agencyId;
            if (!aId) {
              aId = -1;
            }
            apiGet(`mail/templates/${this.location}/${aId}`).then((templateResult) => {
              if (result) {
                this.emailTemplates = templateResult;
              }
            });
          })
          .finally(() => {
            this.$nextTick(() => {
              this.loading = false;
            });
          });
      }
    },

    delphiColorToHex(number) {
      let str = number.toString(16);
      str = str.padStart(6, '0');
      const R = str.substring(4, 6);
      const G = str.substring(2, 4);
      const B = str.substring(0, 2);
      // return str+':'+R+' '+G+' '+B;
      return R + G + B;
    },

    propertiesFormClosed(param) {
      this.showPropertiesForm = false;
      if (param.btn == 'save') {
        // Re-mapping the webaccess prop in order to find changes in the backend
        let orgDocument = this.document;
        orgDocument = this.createWebAccessObjectFromIds(orgDocument);

        // Re-mapping the webaccess prop in order to find changes in the backend
        let newDocument = param.value;
        newDocument = this.createWebAccessObjectFromIds(newDocument);

        apiPut('/document', { value: newDocument, orgValue: orgDocument }).then(() => {
          this.getDocuments(this.parentId);
        });
      }

      this.document = {};
    },
    showProperties(rowItem) {
      if (!this.applicationStore.userRight('DOC.NEW_AND_EDIT')) {
        return;
      }

      this.document = rowItem;
      this.showPropertiesForm = true;
    },

    rowMenuClicked(event) {
      const orgDoc = GlobalHelperFunctions.duplicateViaJson(event.rowItem);
      if (event.menuItem.name == 'change title') {
        this.showProperties(event.rowItem);
      } else if (event.menuItem.name == 'setLabel') {
        // eslint-disable-next-line no-param-reassign
        event.rowItem = this.createWebAccessObjectFromIds(event.rowItem); // make a webacces objects from ID's
        const newDoc = GlobalHelperFunctions.duplicateViaJson(event.rowItem);
        newDoc.LABEL_ID = event.menuItem.label.DLA_ID;
        apiPut('/document', { value: newDoc, orgValue: event.rowItem }).then((result) => {
          if (result.resultCategory == 1) {
            const doc = this.documents.find((item) => item.ID == event.rowItem.ID);
            if (doc) {
              doc.LABEL_ID = event.menuItem.label.DLA_ID;
              doc.WEB_ACCESS = orgDoc.WEB_ACCESS; // Set the webacces object back to array of ID's
            }
          }
        });
      } else if (event.menuItem.name == 'remove label') {
        if (event.rowItem.LABEL_ID) {
          // eslint-disable-next-line no-param-reassign
          event.rowItem = this.createWebAccessObjectFromIds(event.rowItem); // make a webacces objects from ID's
          const newDoc = GlobalHelperFunctions.duplicateViaJson(event.rowItem);
          newDoc.LABEL_ID = null;
          apiPut('/document', { value: newDoc, orgValue: event.rowItem }).then((result) => {
            if (result.resultCategory == 1) {
              const doc = this.documents.find((item) => item.ID == event.rowItem.ID);
              if (doc) {
                doc.LABEL_ID = null;
                doc.WEB_ACCESS = orgDoc.WEB_ACCESS; // Set the webacces object back to array of ID's
              }
            }
          });
        }
      } else if (event.menuItem.name == 'delete document') {
        this.selectedDocumentIds = []; // reset selectedDocumentIds.
        const id = event.rowItem.ID;
        this.selectedDocumentIds.push(id);
        this.showDeleteDocumentsDialog = true;
        /* if(id){
                      this.deleteDocumentById(id);
                  } */
      } else {
        // console.log(event);
      }
    },

    createWebAccessObjectFromIds(obj) {
      if (obj.WEB_ACCESS) {
        // eslint-disable-next-line no-param-reassign
        obj.WEB_ACCESS = obj.WEB_ACCESS.map((item) => ({
          WAD_DOCUMENT_ID: obj.ID,
          WAD_WMG_ID: item,
          WAD_WMVER: 1,
        }));
      }
      return obj;
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>

<style scoped>
.selectbox {
  padding-top: 10px;
  margin-bottom: -10px;
}

.filename {
  position: relative;
  top: -5px;
  left: 3px;
}

.filelink {
  cursor: pointer;
}

.rightBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 0px;
}
</style>
