import { render, staticRenderFns } from "./WmButtonGroup.vue?vue&type=template&id=0c2f9210&scoped=true&"
import script from "./WmButtonGroup.vue?vue&type=script&lang=js&"
export * from "./WmButtonGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c2f9210",
  null
  
)

export default component.exports