<template>
  <div>
    <v-layout v-if="!clientId" wrap class="pt-2 pl-2 ma-0">
      <gat-flex size="m">
        <v-switch
          v-model="showAll"
          :true-value="false"
          :false-value="true"
          label="Show only my follow-ups"
          class="ma-0">
        </v-switch>
      </gat-flex>
    </v-layout>
    <gat-grid
      title="Follow ups"
      class="elevation-0"
      :columns="columns"
      :items="filteredFollowUps"
      :hideEmptyColumns="true"
      :reloadBtn="true"
      :fixedheader="true"
      height="calc(100vh - 170px)"
      :orgSortBy="[
        { field: 'CFU_CLOSED', desc: false },
      ]"
      :isLoading="isLoading"
      @reload-clicked="getFollowUps"
      @row-clicked="edit">
      <template slot="btns" v-if="clientId">
        <wm-button-group class="right mr-1" :buttons="[{ btnType: 'ADD', mainAction: true }]" @addClick="addFollowUp" />
      </template>
      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == `CFU_CLOSED`">
          <span v-if="props.row.CFU_CLOSED == 1">X</span>
        </div>
        <div v-else-if="props.column.field == `CFU_NEXT_FOLLOWUP`">
          <span v-if="props.row.CFU_CLOSED == 1"></span>
          <span v-else-if="props.row.CFU_CLOSED == 0" :class="getNextFollowUpTextColor(props.row)">{{ formatNextFollowUpDate(props.row) }}</span>
          <span v-else>{{ formatNextFollowUpDate(props.row) }}</span>
        </div>
        <div v-else-if="props.column.field == `CFU_DATETIME`">
          <span>{{ formatLastFollowUpDate(props.row.CFU_DATETIME) }}</span>
        </div>
      </template>
    </gat-grid>
    <follow-up-details
      :followUpItem="currentItem"
      :dialog="showDialog"
      @cancel="showDialog = false"
      @save="formBtnClicked"
      @delete="formBtnClicked" />
  </div>
</template>

<script>
import moment from 'moment';
import GatUtils from '@/app-components/GatUtils';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete, apiPost } from '@/store/api';
import { DateTime } from 'luxon';
import { useHelperStore } from '@/store/helperStore';
import { useApplicationStore } from '@/store/applicationStore';
import { getPendingActivities } from '@/services/api/api-calls/getPendingActivities';
import FollowUpDetails from './FollowUpDetails.vue';

export default {
  name: 'FollowUps',
  props: ['clientId'],
  components: { FollowUpDetails },
  setup() {
    const applicationStore = useApplicationStore();
    const helperStore = useHelperStore();
    return {
      applicationStore,helperStore
    };
  },
  data() {
    return {
      moment,
      followUps: [],
      showDialog: false,
      showAll: false,
      currentItem: null,
      orgItem: {},
      isLoading: false,
    };
  },
  computed: {
    columns() {
      const result = [];
      /* result.push({header:"Alert", field:"CFU_ALERT"}); */
      result.push({ header: 'Closed', field: 'CFU_CLOSED' });
      result.push({ header: 'Next follow up', field: 'CFU_NEXT_FOLLOWUP' });
      result.push({ header: 'Last follow up', field: 'CFU_DATETIME', hideOnPhone: true });
      result.push({ header: 'Usercode', field: 'CFU_USER', hideOnPhone: true });
      result.push({ header: 'Username', field: 'CFU_USRNAME', hideOnPhone: true });
      result.push({
        header: 'Company',
        field: 'ClientName',
        linkUrl: '/client/field[CFU_CLIENT_ID]/followup',
        linkText: 'field[ClientName]',
        hide: this.clientId,
      });
      result.push({ header: 'Comments', field: 'CFU_NOTE', hideOnPhone: true });
      return result;
    },

    filteredFollowUps() {
      let followUps = this.followUps.map(flup => {
        // eslint-disable-next-line no-param-reassign
        flup.CFU_USRNAME = this.helperStore.users.find(usr => usr.KODE === flup.CFU_USER)?.navn ?? null;
        return flup;
      })
      if (!this.clientId) {
        followUps = followUps.filter(
          (item) =>
            (item.CFU_USER == this.applicationStore.user.userName && item.CFU_CLOSED != 1) ||
            (this.showAll && item.CFU_CLOSED != 1)
        );
      }
      followUps = followUps.sort((a, b) => {
        const bothSame = a.CFU_CLOSED - b.CFU_CLOSED
        if(bothSame === 0) {
          const aDate = new Date(a.CFU_DATETIME).getTime();
          const bDate = new Date(b.CFU_DATETIME).getTime();
          const aDate2 = new Date(a.CFU_NEXT_FOLLOWUP).getTime();
          const bDate2 = new Date(b.CFU_NEXT_FOLLOWUP).getTime();
          if(a.CFU_CLOSED === 1) {
            return bDate - aDate || aDate2 - bDate2
          }
          return aDate2 - bDate2 || aDate - bDate
        }
        return bothSame
      })
      return followUps;
    },

    isDesc() {
      return !!this.clientId;
    },
  },
  watch: {
    clientId: {
      immediate: true,
      handler(newVal) {
        if (this.followUps.length == 0 || newVal) {
          this.getFollowUps();
        }
      },
    },
  },
  methods: {
    since(date) {
      const newDate = new Date(date);
      return moment(newDate).fromNow();
    },

    addFollowUp() {
      this.currentItem = {
        CFU_CLIENT_ID: this.clientId,
        CFU_DATETIME: DateTime.now().toISODate(),
        CFU_USER: this.applicationStore.user.userName,
        CFU_USRNAME: this.applicationStore.user.fullName,
        CFU_CLOSED: 0,
        CFU_NOTE: '',
      };
      this.orgItem = {};
      this.showDialog = true;
    },

    edit(item) {
      this.currentItem = GatUtils.makeCopyViaJson(item);
      this.orgItem = item;
      this.showDialog = true;
    },

    findAndRemoveFollowUpItem(followUpItem) {
      const idx = this.followUps.findIndex((item) => item.CFU_ID == followUpItem.CFU_ID);
      if (idx >= 0) {
        this.followUps.splice(idx, 1);
        return true;
      }
      return false;
    },

    findAndUpdateFollowUpItem(followUpItem) {
      const idx = this.followUps.findIndex((item) => item.CFU_ID == followUpItem.CFU_ID);
      if (idx >= 0) {
        this.followUps.splice(idx, 1, followUpItem);
        return true;
      }
      return false;
    },

    formBtnClicked(btn) {
      this.showDialog = false;

      if (btn == 'save') {
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiPut('/followUp', { value: this.currentItem, orgValue: this.orgItem }).then(() => {
          // remove progressbar
          this.isLoading = false;
          window.messageBus.$emit('followUp-updated', this.currentItem);
          getPendingActivities();
        });
      } else if (btn == 'delete') {
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiDelete(`/followUp/${this.currentItem.CFU_ID}`).then(() => {
          this.isLoading = false;
          window.messageBus.$emit('followUp-removed', this.currentItem);
          getPendingActivities();
        });
      }
    },

    formatDate(date, format) {
      return moment(date).format(format);
    },

    formatNextFollowUpDate(item) {
      const dateStr = item.CFU_NEXT_FOLLOWUP ? moment(item.CFU_NEXT_FOLLOWUP).format(this.applicationStore.settings.dateFormat) : null;
      return dateStr;
    },
    formatLastFollowUpDate(item) {
      const txt = moment(item).format(this.applicationStore.settings.dateFormat);
      return txt;
    },

    getFollowUps(days) {
      if (this.clientId) {
        apiGet(`/followUp/${this.clientId}`).then((result) => {
          this.followUps = result.payload;
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        if (!days) days = 30;
        apiPost('/followup', { Days: days }).then((result) => {
          this.followUps = result.payload;
        });
      }
    },

    getNextFollowUpTextColor(item) {
      const result = item && moment(item.CFU_NEXT_FOLLOWUP).toDate() < Date.now() ? 'red--text' : null;
      return result;
    },

    momentFromNow(val) {
      return moment(val).fromNow();
    },

    rowClicked(rowData) {
      for (let index = 0; index < this.followUps.length; index++) {
        const element = this.followUps[index];
        if (element.CFU_ID == rowData.CFU_ID) {
          // eslint-disable-next-line no-param-reassign
          rowData.editMode = true;
          this.followUps.splice(index, 1, rowData); // to trigger change we cannot set by index directly
        } else if (element.editMode == true) {
          element.editMode = false;
          this.followUps.splice(index, 1, element);
        }
      }
    },
  },

  created() {
    window.messageBus.$on('followUp-updated', (followUpItem) => {
      const found = this.findAndUpdateFollowUpItem(followUpItem);
      if (!found) {
        this.getFollowUps();
      }
    });
    window.messageBus.$on('followUp-removed', (followUpItem) => {
      const found = this.findAndRemoveFollowUpItem(followUpItem);
      if (!found) {
        this.getFollowUps();
      }
    });
  },
};
</script>

<style scoped>
.expiredFollowUp {
  color: red;
}
.cardEdit {
  position: absolute;
  bottom: 0px;
  right: 100px;
}
</style>
