<template>
  <gat-form class="pb-12" ref="gatform" :isLoading="isLoading">
    <!-- <div>TemplateId: {{applicationStore.globalSettings.SGL_EXP_TEMPLATE_MANDATORY}}</div> -->
    <!-- <div>Expense {{expenseType}}</div> -->
    <!-- <div>ServiceId: {{serviceId}}</div> -->
    <v-alert v-if="debtor.SHOW_WARNING === 1 && applicationStore.user.internal" color="orange darken-1" dense text
      type="warning" outlined>
      <span class="font-weight-medium">{{ debtor.displayName }}</span><br />
      {{ debtor.WARNING }}
    </v-alert>
    <v-alert v-if="debtor.CLIENT_CREDIT_FLAG > 0 && applicationStore.user.internal"
      :color="getClienCreditFlagColor(debtor.CLIENT_CREDIT_FLAG, true)" icon="mdi-flag" dense text outlined>
      <span class="font-weight-medium">{{ debtor.displayName }}</span><br />
      credit balance: {{ debtor.CLIENT_CREDIT_BALANCE }}
      {{ applicationStore.getAgencySettings(portcallStore.callDetails.SETUP_ID).LocalCurrency }}
    </v-alert>
    <v-alert v-if="isInvoiceExportPresent && expenseType != 1" outlined color="blue" dense text type="info">
      This expense line is {{ alertText }} and cannot be edited.
    </v-alert>
    <v-alert v-if="expenseType == 1" outlined color="blue" dense text type="info">
      This expense line is a sublines expense and cannot be edited in the web module.
    </v-alert>
    <GatGroup title="Expense type" :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field size="m" label="Group"
            :value="lookupCode(expense.EXPENSE_GROUP_ID, expenseGroups, 'GroupId', 'GroupName')" />
          <gat-compact-field size="m" label="Template"
            :value="lookupCode(expense.EXPENSE_TEMPLATE_ID, expenseTemplates, 'ELT_ID', 'TemplateName')" />
          <gat-compact-field size="m" label="Budget/proforma" :value="budget" checkbox />

          <!-- <gat-flex size="m">
                      <v-radio-group v-model="budget"  mandatory row>
                          <v-radio label="Proforma / budget" value=1></v-radio>
                          <v-radio label="Final / actual" value=0></v-radio>
                      </v-radio-group>
                  </gat-flex>                                    -->
        </v-layout>
      </template>
      <v-layout wrap>
        <gat-select class="shortcut" size="xs" label="Shortcut" v-model="expense.EXPENSE_TEMPLATE_ID" :autXofocus="true"
          :items="expenseTemplates" textFieldName="Shortcut" codeFieldName="ELT_ID" :columns="shortcutColumns"
          @item-selected="expenseTemplateChanged" :disabled="isInvoiceExportPresent || (isNew ? false : applicationStore.globalSettings.SGL_EXP_TEMPLATE_LOCKED == 1)
            " :readonly="!userCanEdit" />
        <gat-select size="sm" label="Group" v-model="expense.EXPENSE_GROUP_ID" :required="true" :items="expenseGroups"
          textFieldName="GroupName" codeFieldName="GroupId" :colusmns="templateColumns"
          @item-selected="expenseGroupChanged" :disabled="isInvoiceExportPresent || (isNew ? false : applicationStore.globalSettings.SGL_EXP_TEMPLATE_LOCKED == 1)
            " :readonly="!userCanEdit" />
        <gat-select label="Template" v-model="expense.EXPENSE_TEMPLATE_ID"
          :required="applicationStore.globalSettings.SGL_EXP_TEMPLATE_MANDATORY == 1" :items="expenseTemplatesByGroup"
          textFieldName="TemplateName" codeFieldName="ELT_ID" :columns="templateColumns"
          @item-selected="expenseTemplate = $event" :disabled="isInvoiceExportPresent || (isNew ? false : applicationStore.globalSettings.SGL_EXP_TEMPLATE_LOCKED == 1)
            " :readonly="!userCanEdit" />
        <gat-edit label="Budget / proforma" v-model="setExpenseAsBudget" checkbox :disabled="isInvoiceExportPresent"
          :checkedValue="1" :uncheckedValue="0" :readonly="!userCanEdit" />
        <!-- <gat-flex size="m">
                  <v-radio-group v-model="budget"  mandatory row>
                      <v-radio label="Proforma / budget" value=1></v-radio>
                      <v-radio label="Final / actual" value=0></v-radio>
                  </v-radio-group>
             </gat-flex> -->
      </v-layout>
    </GatGroup>

    <GatGroup title="Debtor and creditor" :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field size="m" label="Client / debtor" :value="debtor.NAME"
            :disabled="isInvoiceExportPresent && isDebtorEditable" />
          <gat-compact-field size="m" label="Supplier / creditor" :value="creditor.NAME"
            :disabled="isInvoiceExportPresent && isDebtorEditable" />
        </v-layout>
      </template>
      <v-layout wrap>
        <gat-client-select v-if="!hotlistOnly" label="Client (debtor)" v-model="expense.CLIENT_ID" category="0"
          @item-selected="debtor = $event" :readonly="!userCanEdit || !applicationStore.user.internal" :required="true"
          :clearable="userCanEdit && applicationStore.user.internal"
          :disabled="(isInvoiceExportPresent && isDebtorEditable) || expenseType == 1"
          :showClientCreditLimit="applicationStore.user.internal" :showClientWarning="applicationStore.user.internal" />
        <gat-select v-if="hotlistOnly" :items="hotlistDebtors" textFieldName="displayName" codeFieldName="value"
          label="Client (debtor)" v-model="expense.CLIENT_ID" category="0" @item-selected="debtor = $event"
          :readonly="!userCanEdit || !applicationStore.user.internal"
          :disabled="(isInvoiceExportPresent && isDebtorEditable) || expenseType == 1" customItem>
          <template v-slot:item="{ data }">
            <div>{{ data['displayName'] }}</div>
            <div v-if="+data.SHOW_WARNING === 1 || data.CLIENT_CREDIT_FLAG > 0" class="ml-auto">
              <v-icon v-if="+data.SHOW_WARNING === 1" class="orange--text"
                :class="data.CLIENT_CREDIT_FLAG > 0 ? 'mr-1' : ''">mdi-alert</v-icon>
              <v-icon v-if="data.CLIENT_CREDIT_FLAG > 0"
                :class="getClienCreditFlagColor(+data.CLIENT_CREDIT_FLAG)">mdi-flag</v-icon>
            </div>
          </template>
        </gat-select>
        <gat-client-select v-if="!(hotlistOnly && applicationStore.globalSettings.SGL_EXP_RESTRICT_SUPPLIER_HOTLIST)"
          label="Supplier (creditor)" v-model="expense.SUPPLIER_ID" @item-selected="creditor = $event" category="1"
          :disabled="(isInvoiceExportPresent && isDebtorEditable) || expenseType == 1" :readonly="!userCanEdit" />
        <gat-select v-if="hotlistOnly && applicationStore.globalSettings.SGL_EXP_RESTRICT_SUPPLIER_HOTLIST"
          :items="hotlistCreditors" label="Supplier (creditor)" textFieldName="displayName" codeFieldName="value"
          v-model="expense.SUPPLIER_ID" category="0" @item-selected="debtor = $event"
          :disabled="(isInvoiceExportPresent && isDebtorEditable) || expenseType == 1" :readonly="!userCanEdit" />
        <gat-edit v-model="hotlistOnly" label="Limit to connected clients" toggle
          :disabled="(isInvoiceExportPresent && isDebtorEditable) || expenseType == 1" :readonly="!userCanEdit" />
      </v-layout>
    </GatGroup>

    <GatGroup title="Description"
      :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown || !applicationStore.user.internal">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field size="m" label="Description" :value="expense.EXPENSE_TEXT"
            :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="xs" label="Cost code" :value="expense.ACCOUNT_CODE"
            :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="xs" label="Ref no" :value="expense.REF_NUMBER" :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="s" label="Date" :value="expense.EXPENSE_DATE" :isDate="true"
            :disabled="isInvoiceExportPresent && isDatetimeEditable" />
          <gat-compact-field size="l" label="Additional information" :value="expense.MISC"
            :disabled="isInvoiceExportPresent && isMiscEditable" />
        </v-layout>
      </template>
      <v-layout wrap>
        <gat-edit label="Text / short description" :required="true" v-model="expense.EXPENSE_TEXT"
          :disabled="isInvoiceExportPresent" :maxChars="100" :readonly="!userCanEdit" />
        <gat-edit size="sm" label="Cost code" v-model="expense.ACCOUNT_CODE" :disabled="true" :maxChars="applicationStore.getColumnLength('creditnote_lines.CNL_COSTCODE')
          " /><!-- :readonly=true :disabled="isInvoiceExportPresent" -->
        <gat-edit size="xs" label="Ref no" v-model="expense.REF_NUMBER" dataType="number"
          :disabled="isInvoiceExportPresent || applicationStore.globalSettings.SGL_EXP_DISABLE_REF_NO == 1"
          :readonly="!userCanEdit" noThousandSeperator />
        <gat-edit size="sm" label="Date" v-model="expense.EXPENSE_DATE" dateEdit
          :disabled="(isInvoiceExportPresent && isDatetimeEditable) || expenseType == 1" :readonly="!userCanEdit" />
        <gat-edit size="xs" v-show="applicationStore.getAgencySettings(agencyId).EXP_TIME_ON" label="Time"
          v-model="expense.EXPENSE_TIME" timeEdit :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
        <gat-edit size="xl" label="Additional information" v-model="expense.MISC" :rows="3" :maxChars="4000"
          :disabled="isInvoiceExportPresent && isMiscEditable" :readonly="!userCanEdit" />
      </v-layout>
    </GatGroup>

    <!-- CTM -->
    <GatGroup v-if="expenseType == 3" title="Amounts"
      :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown || !applicationStore.user.internal">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field size="s" label="CTM amount" :value="expense.AMOUNT_FOREIGN_CURRENCY"
            :appendText="expense.CTM_Currency" :isCurrency="true" :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="m" label="Amount as text" :value="CTMAmountAsText"
            :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="s" label="ROE" :value="expense.ROE_CTM" maxDecimals="10"
            :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="s" label="Amount" :value="expense.AMOUNT_FOREIGN_CURRENCY * expense.ROE_CTM"
            :appendText="expense.CurrencyName" :isCurrency="true" :disabled="isInvoiceExportPresent" />
        </v-layout>
      </template>
      <v-layout wrap>
        <gat-edit size="s" label="CTM Amount" v-model="expense.AMOUNT_FOREIGN_CURRENCY" dataType="number"
          :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
        <gat-select size="s" v-model="expense.CTM_Currency" label="Currency" :items="currencies"
          @item-selected="CTMCurrencyChanged" :disabled="isInvoiceExportPresent" :readonly="!userCanEdit">
        </gat-select>
        <gat-edit size="m" label="Amount as text" :readonly="true" :value="CTMAmountAsText" :max-chars="0"
          :disabled="isInvoiceExportPresent" />
        <gat-edit size="s" label="ROE" v-model="expense.ROE_CTM" dataType="number" hint="Rate Of Exchange" decimals="10"
          :disabled="isInvoiceExportPresent || budget == 1" :readonly="!userCanEdit || budget == 1" required />
        <gat-edit size="s" :readonly="true" label="Amount"
          :value="Math.round(expense.AMOUNT_FOREIGN_CURRENCY * expense.ROE_CTM * 100) / 100" dataType="number"
          :isCurrency="true" :disabled="true" :appendText="applicationStore.getAgencySettings(agencyId).LocalCurrency" />
      </v-layout>
    </GatGroup>
    <!-- Advance -->
    <GatGroup v-else-if="expenseType == 2" title="Amounts"
      :collapsed="$vuetify.breakpoint.mdAndDown || !applicationStore.user.internal">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field size="sm" label="Asked for" :value="expense.AMOUNT_ADVANCE"
            :appendText="expense.CurrencyName" :isCurrency="true" :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="sm" label="Received" :value="expense.AMOUNT_RECEIVED"
            :appendText="expense.CurrencyName" :isCurrency="true" :disabled="isInvoiceExportPresent" />
        </v-layout>
      </template>
      <v-layout wrap>
        <gat-edit size="s" label="Amount asked for" v-model="expense.AMOUNT_ADVANCE" dataType="number" :isCurrency="true"
          :disabled="isInvoiceExportPresent" :appendText="expense.CurrencyName" :readonly="!userCanEdit" />
        <gat-select size="sm" label="Status" :items="advanceStatus" v-model="expense.RECEIVED"
          @item-selected="advanceStatusChanged" :disabled="isInvoiceExportPresent"
          :readonly="!userCanEdit || !applicationStore.userRight('EX.EDIT_ADVANCE_RECEIVED')" />
        <gat-edit size="s" label="Amount received" v-model="expense.AMOUNT_RECEIVED" dataType="number" :isCurrency="true"
          v-show="expense.RECEIVED > 0" :disabled="isInvoiceExportPresent" :appendText="expense.CurrencyName"
          :readonly="!userCanEdit || !applicationStore.userRight('EX.EDIT_ADVANCE_RECEIVED')" />
        <gat-edit size="s" label="Date recevied" v-model="expense.RECEIVED_DATE" dateEdit v-show="expense.RECEIVED > 0"
          :disabled="isInvoiceExportPresent"
          :readonly="!userCanEdit || !applicationStore.userRight('EX.EDIT_ADVANCE_RECEIVED')" />
        <gat-select size="s" v-model="expense.CurrencyName" label="Currency" :items="currencies"
          @item-selected="currencyChanged" :disabled="isInvoiceExportPresent" :readonly="!userCanEdit">
        </gat-select>
      </v-layout>
    </GatGroup>
    <!-- Normal Expenses -->
    <GatGroup v-else title="Amounts" :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field size="xs" label="Qty" :value="expense.QUANTITY" :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="s" label="Price" :value="expense.PRICE" :appendText="expense.CurrencyName"
            :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="xs" label="Handling" :value="expense.HANDLING" appendText="%"
            :disabled="isInvoiceExportPresent" />
          <gat-compact-field v-show="expense.VAT" size="s" label="Net amount" :value="netAmount" :isCurrency="true"
            :appendText="expense.CurrencyName" :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="xs" label="VAT" :value="expense.VAT" appendText="%"
            :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="s" label="Sum" :value="netAmount + vatAmount" :appendText="expense.CurrencyName"
            :isCurrency="true" :disabled="isInvoiceExportPresent" />
          <gat-compact-field v-show="expense.CurrencyName != applicationStore.getAgencySettings(agencyId).LocalCurrency"
            size="s" :label="'Sum local'" :value="(netAmount + vatAmount) * expense.EXCHANGE_RATE"
            :appendText="applicationStore.getAgencySettings(agencyId).LocalCurrency" :isCurrency="true" />
        </v-layout>
      </template>
      <v-layout wrap v-show="!tableView">
        <gat-edit size="s" label="Quantity" v-model="expense.QUANTITY" dataType="number"
          :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
        <gat-edit size="s" label="Price" v-model="expense.PRICE" dataType="number" :disabled="isInvoiceExportPresent"
          :appendText="expense.CurrencyName" :readonly="!userCanEdit" />
        <gat-edit v-show="expenseTemplate.ELT_USE_HANDLING" size="xs" label="Handling" v-model="expense.HANDLING"
          dataType="number" appendText="%" :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
        <gat-edit v-show="expenseTemplate.ELT_USE_WORK_HOURS" size="xs" label="Man hours" v-model="expense.WORK_HOURS"
          dataType="number" :disabled="isInvoiceExportPresent" appendText="Hrs" :readonly="!userCanEdit" />
        <gat-edit v-show="expenseTemplate.ELT_USE_WORK_HOURS" size="xs" label="Price/hr" v-model="expense.WORK_PRICE"
          dataType="number" :disabled="isInvoiceExportPresent" :appendText="expense.CurrencyName"
          :readonly="!userCanEdit" />
        <gat-edit size="m" v-show="expenseTemplate.ELT_USE_VAT" :disabled="true" label="Net amount" :value="netAmount"
          :decimals="2" dataType="number" :appendText="expense.CurrencyName" :readonly="!userCanEdit" />
        <gat-select v-show="expenseTemplate.ELT_USE_VAT && vatCodes.length > 0" size="s" :items="vatCodes"
          label="VAT code" v-model="expense.VAT_ID" textFieldName="VAT_CODE" codeFieldName="VAT_ID"
          @item-selected="expense.VAT = $event.VAT_PERCENTAGE" :disabled="isInvoiceExportPresent"
          :readonly="!userCanEdit" />
        <gat-edit v-show="expenseTemplate.ELT_USE_VAT" size="s" label="VAT" v-model="expense.VAT" dataType="number"
          appendText="%" :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
        <gat-edit size="m" v-show="expenseTemplate.ELT_USE_VAT" :disabled="true" label="VAT Amount" :value="vatAmount"
          :decimals="2" dataType="number" :appendText="expense.CurrencyName" :readonly="!userCanEdit" />
        <gat-edit size="m" :label="'Sum ' + expense.CurrencyName" :value="netAmount + vatAmount" :decimals="2"
          dataType="number" :disabled="true" :appendText="expense.CurrencyName"
          :readonly="!userCanEdit" /><!-- :disabled="isInvoiceExportPresent" -->
        <gat-select size="s" v-model="expense.CurrencyName" label="Currency" :items="currencies"
          @item-selected="currencyChanged" :disabled="isInvoiceExportPresent" :readonly="!userCanEdit">
        </gat-select>
        <gat-edit size="s" v-show="expense.CurrencyName != applicationStore.getAgencySettings(agencyId).LocalCurrency"
          label="ROE" v-model="expense.EXCHANGE_RATE" dataType="number" hint="Rate Of Exchange"
          :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
        <gat-edit size="s" v-show="expense.CurrencyName != applicationStore.getAgencySettings(agencyId).LocalCurrency"
          :disabled="true" :label="'Sum ' + applicationStore.getAgencySettings(agencyId).LocalCurrency"
          :value="(netAmount + vatAmount) * expense.EXCHANGE_RATE" :decimals="2" dataType="number"
          :appendText="applicationStore.getAgencySettings(agencyId).LocalCurrency" :readonly="!userCanEdit" />
      </v-layout>
      <v-container v-show="tableView" fluid>
        <v-flex class="xs12 md12 lg8 xl6 mb-4">
          <v-row>
            <gat-edit size="xs3" label="Quantity" v-model="expense.QUANTITY" dataType="number"
              :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
            <gat-edit size="xs4" label="Price" v-model="expense.PRICE" dataType="number"
              :disabled="isInvoiceExportPresent" :appendText="expense.CurrencyName" :readonly="!userCanEdit" />
            <gat-edit size="xs5" :disabled="true" label="Amount" :value="expense.QUANTITY * expense.PRICE" :decimals="2"
              dataType="number" :appendText="expense.CurrencyName" :readonly="!userCanEdit" />
          </v-row>
          <v-row v-show="expenseTemplate.ELT_USE_HANDLING">
            <gat-edit size="xs3" label="Handling" v-model="expense.HANDLING" dataType="number" appendText="%"
              :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
            <gat-flex size="xs4" :readonly="!userCanEdit" />
            <gat-edit size="xs5" :disabled="true" label="Handling" :value="handlingAmount" :decimals="2" dataType="number"
              :appendText="expense.CurrencyName" />
          </v-row>
          <v-row v-show="expenseTemplate.ELT_USE_WORK_HOURS">
            <gat-edit size="xs3" label="Man hours" v-model="expense.WORK_HOURS" dataType="number"
              :disabled="isInvoiceExportPresent" appendText="Hrs" :readonly="!userCanEdit" />
            <gat-edit size="xs4" label="Price/hr" v-model="expense.WORK_PRICE" dataType="number"
              :disabled="isInvoiceExportPresent" :appendText="expense.CurrencyName" :readonly="!userCanEdit" />
            <gat-edit size="xs5" :disabled="true" label="Work amount" :value="expense.WORK_HOURS * expense.WORK_PRICE"
              :decimals="2" dataType="number" :appendText="expense.CurrencyName" />
          </v-row>

          <v-row v-show="expenseTemplate.ELT_USE_VAT">
            <gat-edit size="xs3" :disabled="true" label="Net amount" :value="netAmount" :decimals="2" dataType="number"
              :appendText="expense.CurrencyName" />
            <gat-select v-show="vatCodes.length > 0" size="xs2" :items="vatCodes" label="VAT code"
              v-model="expense.VAT_ID" :disabled="isInvoiceExportPresent" textFieldName="VAT_CODE" codeFieldName="VAT_ID"
              @item-selected="expense.VAT = $event.VAT_PERCENTAGE" :readonly="!userCanEdit" />
            <gat-edit size="xs2" label="VAT" v-model="expense.VAT" dataType="number" appendText="%"
              :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
            <gat-flex size="xs2" v-show="!(vatCodes.length > 0)" :readonly="!userCanEdit" />
            <gat-edit size="xs5" :disabled="true" label="VAT Amount" :value="vatAmount" :decimals="2" dataType="number"
              :appendText="expense.CurrencyName" />
          </v-row>
        </v-flex>

        <v-layout class="xs12" wrap>
          <v-flex class="xs12 md12 lg8 xl6 mr">
            <v-row>
              <gat-select size="xs3" v-model="expense.CurrencyName" label="Currency" :items="currencies"
                @item-selected="currencyChanged" :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
              <gat-edit size="xs4"
                v-show="expense.CurrencyName != applicationStore.getAgencySettings(agencyId).LocalCurrency" label="ROE"
                v-model="expense.EXCHANGE_RATE" dataType="number" hint="Rate Of Exchange"
                :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
              <gat-flex size="xs4"
                v-show="expense.CurrencyName == applicationStore.getAgencySettings(agencyId).LocalCurrency" />
              <gat-edit size="xs5" :label="'Sum ' + expense.CurrencyName" :value="netAmount + vatAmount" :decimals="2"
                dataType="number" :disabled="true" :appendText="expense.CurrencyName" />
              <gat-edit size="xs4" v-show="$vuetify.breakpoint.smAndDown &&
                expense.CurrencyName != applicationStore.getAgencySettings(agencyId).LocalCurrency
                " :disabled="true" :label="'Sum ' + applicationStore.getAgencySettings(agencyId).LocalCurrency"
                :value="(netAmount + vatAmount) * expense.EXCHANGE_RATE" :decimals="2" dataType="number"
                :appendText="applicationStore.getAgencySettings(agencyId).LocalCurrency" />
            </v-row>
          </v-flex>
          <v-flex class="xs12 md6 lg5 xl4 ml-4">
            <v-row>
              <gat-edit size="xs4" v-show="$vuetify.breakpoint.mdAndUp &&
                expense.CurrencyName != applicationStore.getAgencySettings(agencyId).LocalCurrency
                " :disabled="true" :label="'Sum ' + applicationStore.getAgencySettings(agencyId).LocalCurrency"
                :value="(netAmount + vatAmount) * expense.EXCHANGE_RATE" :decimals="2" dataType="number"
                :appendText="applicationStore.getAgencySettings(agencyId).LocalCurrency" />
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
    </GatGroup>

    <GatGroup title="Other spesifiactions" :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field size="s" label="Voucher ref" :value="expense.VOUCHER_REF"
            :disabled="isInvoiceExportPresent && isVoucherEditable" />
          <gat-compact-field size="s" label="PO no" :value="expense.PO_NO"
            :disabled="isInvoiceExportPresent && isVoucherEditable" />
          <gat-compact-field size="xs" label="Sort order" :value="expense.SORT_ORDER"
            :disabled="isInvoiceExportPresent && isVoucherEditable" />
          <gat-compact-field v-if="applicationStore.getAgencySettings(agencyId).EXP_CODE1_CAPTION" size="s"
            :label="applicationStore.getAgencySettings(agencyId).EXP_CODE1_CAPTION" :value="expense.CODE1"
            :disabled="isInvoiceExportPresent && isVoucherEditable" />
          <gat-compact-field v-if="applicationStore.getAgencySettings(agencyId).EXP_CODE2_CAPTION" size="s"
            :label="applicationStore.getAgencySettings(agencyId).EXP_CODE2_CAPTION" :value="expense.CODE2"
            :disabled="isInvoiceExportPresent && isVoucherEditable" />
        </v-layout>
      </template>
      <v-layout wrap>
        <gat-edit size="s" v-model="expense.VOUCHER_REF"
          :required="expenseTemplate.ELT_MAN_VOUCHER_REF_NOT_BUDGET == 1 && budget == '0'" label="Voucher ref"
          :disabled="(isInvoiceExportPresent && isVoucherEditable) || expenseType == 1" :maxChars="100"
          :readonly="!userCanEdit" />
        <gat-edit v-show="applicationStore.globalSettings.SGL_EXP_USE_PO_NO"
          :readonly="!applicationStore.userRight('EX2.EDIT_PO_NUMBER')" size="s" v-model="expense.PO_NO" label="PO number"
          :disabled="true" :maxChars="applicationStore.getColumnLength('Expense.PO_NO')">
          <span v-show="!expense.PO_NO && !applicationStore.userRight('EX2.EDIT_PO_NUMBER')" slot="append"><v-btn small
              icon @click="addPoNo" :disabled="!userCanEdit"><v-icon small>add</v-icon></v-btn></span>
        </gat-edit>
        <gat-edit size="xs" v-model="expense.SORT_ORDER" dataType="number" label="Sort order"
          :disabled="(isInvoiceExportPresent && isVoucherEditable) || expenseType == 1" :readonly="!userCanEdit" />
        <gat-edit size="s" v-if="applicationStore.getAgencySettings(agencyId).EXP_CODE1_CAPTION" v-model="expense.CODE1"
          :label="applicationStore.getAgencySettings(agencyId).EXP_CODE1_CAPTION"
          :disabled="(isInvoiceExportPresent && isVoucherEditable) || expenseType == 1" :maxChars="100"
          :readonly="!userCanEdit" />
        <gat-edit size="s" v-if="applicationStore.getAgencySettings(agencyId).EXP_CODE2_CAPTION" v-model="expense.CODE2"
          :label="applicationStore.getAgencySettings(agencyId).EXP_CODE2_CAPTION"
          :disabled="(isInvoiceExportPresent && isVoucherEditable) || expenseType == 1" :maxChars="100"
          :readonly="!userCanEdit" />
      </v-layout>
    </GatGroup>

    <GatGroup v-if="!isNew" title="Approved" :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field size="xs" label="Status" :value="approvedLabel" />
          <gat-compact-field size="l" label="Reject comment" :value="expense.APPROVAL_COMMENT" />
        </v-layout>
      </template>
      <v-layout wrap>
        <v-radio-group v-model="expense.APPROVED" row class="ml-4" :disabled="!applicationStore.userRight('EX.APPROVE')">
          <v-radio label="Approved" :value="1" :readonly="!userCanEdit" :disabled="expenseType == 1" />
          <v-radio label="Rejected" :value="2" :readonly="!userCanEdit" :disabled="expenseType == 1" />
          <v-radio label="None" :value="0" :readonly="!userCanEdit" :disabled="expenseType == 1" />
        </v-radio-group>
      </v-layout>
      <gat-edit size="xxl" label="Reject comment" v-model="expense.APPROVAL_COMMENT" :rows="3" :maxChars="4000"
        :disabled="expense.APPROVED != 2 || expenseType == 1" :readonly="!userCanEdit" />
    </GatGroup>

    <GatGroup v-if="applicationStore.user.internal &&
      currentTemplate &&
      (currentTemplate.ELT_SHOW_ALL_DYNA || (currentTemplate.dynaFieldIds && currentTemplate.dynaFieldIds.length))
      " title="User-defined fields" :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown">
      <template slot="compact">
        <gat-dyna-fields v-model="expense.dynaValues" componentName="pDynaFields.ExpenseDyna" container="pDynaFields"
          :compact="true" />
      </template>
      <gat-dyna-fields v-model="expense.dynaValues" componentName="pDynaFields.ExpenseDyna" container="pDynaFields"
        :filterByIds="dynaFilter" :disabled="isInvoiceExportPresent" :readonly="!userCanEdit" />
    </GatGroup>

    <GatGroup title="Documents" :collapsed="isNew ? !expandedIfNew : true">
      <template slot="compact">
        <embedded-doc-list v-if="!isNew" location="expense" :parentId="expenseId" :compact="true"
          :disabled="isInvoiceExportPresent && isAttachmentEditable" @files-selected="setFilesToUpload" />
        <span v-if="isNew">The expense must be created in order to add documents.</span>
      </template>
      <v-layout wrap>
        <embedded-doc-list v-if="!isNew" location="expense" :parentId="expenseId" :disabled="isDocListDisabled"
          @files-selected="setFilesToUpload" />
      </v-layout>
      <span v-if="isNew">The expense must be created in order to add documents.</span>
    </GatGroup>

    <GatGroup title="Revenue" :collapsed="isNew ? !expandedIfNew : false"
      v-show="applicationStore.globalSettings.SGL_USE_PROFIT && expenseType == 0" v-if="applicationStore.user.internal">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field size="xs" label="Rate" :value="expense.PROFIT_PCT" appendText="%"
            :disabled="isInvoiceExportPresent && isRevenueEditable" />
          <gat-compact-field size="s" label="Fixed amount" :value="expense.PROFIT" :isCurrency="true"
            :disabled="isRevenueEditable" />
          <gat-compact-field size="xs" label="Handling" :value="handlingAmount"
            :disabled="isInvoiceExportPresent && isRevenueEditable" />
          <gat-compact-field size="s" label="Sum revenue" :value="revenue" :isCurrency="true"
            :disabled="isInvoiceExportPresent && isRevenueEditable" />
        </v-layout>
      </template>
      <v-layout wrap>
        <gat-edit size="xs" label="Rate" v-model="expense.PROFIT_PCT" dataType="number" appendText="%"
          :disabled="isInvoiceExportPresent && isRevenueEditable" :readonly="!userCanEdit" />
        <gat-edit size="s" label="Fixed amount" v-model="expense.PROFIT" dataType="number"
          :disabled="isInvoiceExportPresent && isRevenueEditable" :appendText="expense.CurrencyName"
          :readonly="!userCanEdit" />
        <gat-edit size="s" v-show="expenseTemplate.ELT_USE_HANDLING" label="Handling" :value="handlingAmount"
          :decimals="2" dataType="number" :disabled="isInvoiceExportPresent && isRevenueEditable"
          :appendText="expense.CurrencyName" :readonly="!userCanEdit" />
        <gat-edit size="s" label="Sum revenue" :value="revenue" :decimals="2" dataType="number" :disabled="true"
          :appendText="expense.CurrencyName" />
      </v-layout>
    </GatGroup>

    <GatGroup title="History" :collapsed="isNew ? !expandedIfNew : true" v-if="applicationStore.user.internal">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field size="s" label="User" :value="expense.INITIALS" :disabled="isInvoiceExportPresent" />
          <gat-compact-field size="s" label="Original budget" :value="expense.ORIGINAL_BUDGET"
            :disabled="isInvoiceExportPresent" />
          <!-- <gat-compact-field size="s" label="Original actual" :value="expense.ORIGINAL_ACTUAL_VALUE" :disabled="isInvoiceExportPresent" /> -->
        </v-layout>
      </template>
      <v-layout wrap>
        <gat-edit size="s" v-model="expense.INITIALS" label="User" :disabled="isInvoiceExportPresent" :maxChars="100"
          :readonly="!userCanEdit" />
        <gat-edit size="s" v-model="expense.ORIGINAL_BUDGET" label="Original budget amount"
          :disabled="isInvoiceExportPresent" dataType="number" :decimals="2" :appendText="expense.CurrencyName"
          :readonly="!userCanEdit" />
        <!-- <gat-edit size="s" v-model="expense.ORIGINAL_ACTUAL_VALUE" label="Original actual amount" :disabled="isInvoiceExportPresent" dataType="number" :decimals="2" :appendText="expense.CurrencyName" /> -->
      </v-layout>
    </GatGroup>
    <v-dialog v-model="showDialog" persistent max-width="360">
      <v-card>
        <v-card-title class="title">Document{{ filesToUpload.length == 1 ? '' : 's' }} not uploaded</v-card-title>
        <v-card-text>You have not uploaded the following documents:
          <div class="font-weight-bold" v-for="(doc, index) in filesToUpload" :key="index">
            {{ doc.name }}
          </div>
          <br />
          Please discard or upload the selected documents before saving.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <gat-bottom-panel v-if="userCanEdit || userCanDelete" :orgValue="orgExpense" :newValue="expense" :paramId="callId"
      itemDescription="expense" :stopNavigate="true" :saveBtn="userCanEdit"
      :deleteBtn="applicationStore.userRight('EX.DELETE')" :buttonsEnabled="!saving"
      :deleteDisabled="!expense || !expense.ID || !userCanDelete || isInvoiceExportPresent" @saveClicked="saveClicked"
      @deleteClicked="deleteClicked" @duplicateClicked="duplicateClicked" @leftWithoutSaving="clearFormValues">
    </gat-bottom-panel>
  </gat-form>
</template>

<script>
import { calculateTarif } from '@/app-libs/tarif-calculations';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete } from '@/store/api';
import GatClientSelect from '@/app-components/GatClientSelect.vue';
import EmbeddedDocList from '@/app-components/EmbeddedDocList.vue';
import GatDynaFields from '@/app-components/dynafields/GatDynaFields.vue';
import moment from 'moment';
import { defineComponent } from 'vue';
import { useToolbarStore } from '@/store/toolbarStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
import { getExpenseTemplates } from '@/services/api/api-calls/getExpenseTemplates';
import { getVatCodes } from '@/services/api/api-calls/getVatCodes';
import { getExpenses } from '@/services/api/api-calls/getExpenses';
import { getServices } from '@/services/api/api-calls/getServices';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

const writtenNumber = require('written-number');

export default defineComponent({
  name: 'expense',
  props: {
    expenseId: [String, Number],
    callId: [String, Number],
    isNew: Boolean,
    /** Comes from route params. Can be boolean or string */
    duplicate: [String, Boolean],
    serviceId: String,
    templateId: String,
    gridView: String,
  },
  components: { GatClientSelect, EmbeddedDocList, GatDynaFields },
  setup() {
    const toolbarStore = useToolbarStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      portcallStore,
      applicationStore,
      toolbarStore,
    };
  },
  data() {
    return {
      expandedIfNew: false, // is set in activated/deactivated based on isNew prop.
      expense: {},
      orgExpense: {},
      expenseTemplate: {},
      debtor: {},
      creditor: {},
      hotlistOnly: false,
      templateFirstSet: false,
      saving: false,
      tableViewField: true,
      loaded: false,
      loading: false,
      isLoading: false,
      filesToUpload: [],
      showDialog: false,
    };
  },

  activated() {
    if (this.$route.name == 'newServiceExpense' && !this.serviceId) {
      this.$router.push({ name: 'callServices' }); // the user is taken back to services if the newServiceExpense route doesnt have a serviceId.
    }
    if (this.serviceId) {
      this.toolbarStore.setBackNavigation([
        { text: 'port call', href: `/call/${this.callId}` },
        { text: 'service list', href: `/call/${this.callId}/services` },
        { text: 'service ', href: `/call/${this.callId}/service/${this.serviceId}` },
      ]);
    } else {
      const isCrm = this.applicationStore.isCRM;
      if (isCrm) {
        this.toolbarStore.setBackNavigation([
          { text: 'Konsulent Gatship', href: `/call/${this.callId}/expenses?gridView=Konsulent ufakturert` },
          { text: 'Konsulent Politi', href: `/call/${this.callId}/expenses?gridView=Tommy - politi` },
        ]);
      } else {
        this.toolbarStore.setBackNavigation([
          { text: 'port call', href: `/call/${this.callId}` },
          { text: 'expense list', href: `/call/${this.callId}/expenses` },
        ]);
      }
    }
    if (!this.loaded) {
      this.loadExpense(this.expenseId);
    }
    this.toolbarStore.setToolbarNavBtns({ name: '', show: false, doNotRegisterState: true });
    this.expandedIfNew = this.isNew;
  },

  mounted() { },

  deactivated() {
    this.toolbarStore.setBackNavigation(undefined);
    this.loaded = false;
    this.toolbarStore.setToolbarNavBtns({ previousState: true });
    this.expandedIfNew = !this.isNew;
  },

  created() { },

  computed: {
    isDuplicate() {
      if (typeof this.duplicate === 'string') {
        if (this.duplicate === 'true') {
          return true;
        }
        return false;
      }
      return this.duplicate;
    },
    expenseType() {
      return this.expense.EXPENSE_TYPE
    },
    budget() {
      return this.expense.BUDGET
    },
    setExpenseAsBudget: {
      get() {
        const result = this.budget;
        return result;
      },
      set(newVal) {
        const orgVal = this.budget;
        const orgBudget = this.expense.ORIGINAL_BUDGET;
        if (orgVal == 1 && newVal == 0 && !orgBudget && !this.isNew) {
          // set original budget if budget is checked->unchecked and original budget is empty.
          this.expense.ORIGINAL_BUDGET = (this.netAmount + this.vatAmount) * this.expense.EXCHANGE_RATE;
        }
        this.expense.BUDGET = newVal;
      },
    },

    tableView: {
      get() {
        if (this.$vuetify.breakpoint.smAndDown) {
          return false;
        }
        return this.tableViewField;
      },
      set(newVal) {
        this.tableViewField = newVal;
      },
    },

    currencies() {
      const result = [];
      const localCurCode = this.applicationStore.getAgencySettings(this.agencyId).LocalCurrency;
      const cur = [...this.helperStore.currencies.filter(c => !c.INACTIVE)];
      cur.forEach((item) => {
        const curItem = {};
        curItem.value = item.value;
        curItem.text = item.text;
        curItem.ROE = item.ROE;
        curItem.ID = item.IDS[localCurCode];
        result.push(curItem);
      });
      return result;
    },

    approvedLabel() {
      if (this.expense.APPROVED == 2) {
        return 'Rejected';
      }
      if (this.expense.APPROVED == 1) {
        return 'Approved';
      }
      return 'None';
    },

    advanceStatus() {
      return [
        { value: 0, text: 'Not received' },
        { value: 1, text: 'Received' },
        { value: 2, text: 'Partly received' },
      ];
    },

    agencyId() {
      return this.portcallStore.callDetails.SETUP_ID;
    },

    CTMAmountAsText() {
      return `${writtenNumber(this.expense.AMOUNT_FOREIGN_CURRENCY)} ${this.expense.CTM_Currency}`;
    },

    currentTemplate() {
      let result = null;
      let templateId = null;
      if (this.expense.EXPENSE_TEMPLATE_ID) {
        templateId = this.expense.EXPENSE_TEMPLATE_ID;
      } else if (this.templateId) {
        templateId = this.templateId;
      }

      if (templateId) {
        result = this.expenseTemplates.find((item) => item.ELT_ID == templateId);
      }
      return result;
    },

    dynaFilter() {
      if (this.currentTemplate && !this.currentTemplate.ELT_SHOW_ALL_DYNA) {
        return this.currentTemplate.dynaFieldIds;
      }
      return null;
    },

    handlingAmount() {
      return (this.expense.PRICE * this.expense.QUANTITY * this.expense.HANDLING) / 100;
    },

    hotlistCreditors() {
      return this.hotlistClients(1);
    },
    hotlistDebtors() {
      return this.hotlistClients(0);
    },

    alertText() {
      let result = '';
      if (this.expense.INVOICE_ID && this.expense.BATCH_NO) {
        result = 'invoiced and exported';
      } else if (this.expense.INVOICE_ID) {
        result = 'invoiced';
      } else if (this.expense.BATCH_NO) {
        result = 'exported';
      }
      return result;
    },

    // if expense is not invoiced or not exported we can always edit.
    isInvoiceExportPresent() {
      if (this.expense.INVOICE_ID || this.expense.BATCH_NO) {
        return true;
      }
      if (this.expenseType == 1) {
        return true;
      }
      return false;
    },

    // if invoiced or exported, we can only edit if the global settings SGL_EE_REVENUE is set to 1.
    isRevenueEditable() {
      if (this.applicationStore.globalSettings.SGL_EE_REVENUE == 0) {
        return true;
      }
      return false;
    },

    isDebtorEditable() {
      if (this.applicationStore.globalSettings.SGL_EE_DEBTOR == 0) {
        return true;
      }
      return false;
    },

    isDatetimeEditable() {
      if (this.applicationStore.globalSettings.SGL_EE_DATETIME == 0) {
        return true;
      }
      return false;
    },

    isVoucherEditable() {
      if (this.applicationStore.globalSettings.SGL_EE_VOUCHER_REF == 0) {
        return true;
      }
      return false;
    },

    isAttachmentEditable() {
      if (this.applicationStore.globalSettings.SGL_EE_ATTACHMENT == 0) {
        return true;
      }
      return false;
    },

    isMiscEditable() {
      if (this.applicationStore.globalSettings.SGL_EE_MISC == 0) {
        return true;
      }
      return false;
    },

    isDocListDisabled() {
      const result =
        (this.isInvoiceExportPresent && this.isAttachmentEditable) ||
        !this.userCanEdit ||
        (!this.isInternal && !this.applicationStore.userRight('EX.DOWNLOAD_VOUCHER'));
      return result;
    },

    manHourAmount() {
      return this.expense.WORK_HOURS * this.expense.WORK_PRICE;
    },

    netAmount() {
      let result = this.expense.QUANTITY * this.expense.PRICE;
      if (this.expense.HANDLING) {
        result += (result * this.expense.HANDLING) / 100;
      }
      if (this.manHourAmount) {
        result += this.manHourAmount;
      }
      return result;
    },
    isInternal() {
      return this.applicationStore.user.internal;
    },
    userCanDelete() {
      if (this.portcallStore.expenseLocked) {
        return false;
      }
      const { isInternal } = this;
      const internalDeleteRights = this.applicationStore.userRight('EX.DELETE');
      const externalDeleteRights = this.applicationStore.userRight('EX.DELETE') && this.externalUsersCanEdit;
      return isInternal ? internalDeleteRights : externalDeleteRights;
    },
    externalUsersCanEdit() {
      if (
        !this.isInternal &&
        this.expense &&
        this.expense.CLIENT_ID &&
        this.applicationStore.user.externalClientId &&
        this.expense.CLIENT_ID !== this.applicationStore.user.externalClientId
      ) {
        return false;
      }
      return true;
    },

    userCanEdit() {
      if (this.portcallStore.expenseLocked) {
        return false;
      }
      if (this.isNew) {
        return this.applicationStore.userRight('EX.NEW');
      }
      const { isInternal } = this;
      const internalEditRights = this.applicationStore.userRight('EX.EDIT');
      const externalEditRights = this.applicationStore.userRight('EX.EDIT') && this.externalUsersCanEdit;
      return isInternal ? internalEditRights : externalEditRights;
    },

    expenseGroups() {
      const result = [];
      if (this.expenseTemplates) {
        // eslint-disable-next-line array-callback-return
        this.expenseTemplates.map((template) => {
          // eslint-disable-next-line no-return-assign, no-param-reassign
          if (!result.includes((grpItem) => (grpItem.GroupId = template.GroupId))) {
            result.push({ GroupId: template.GroupId, GroupName: template.GroupName, GroupType: template.GROUP_TYPE });
          }
        });
        result.sort((a, b) => {
          if (a.GroupName > b.GroupName) {
            return 1;
          }
          if (a.GroupName < b.GroupName) {
            return -1;
          }
          return 0;
        });
      }
      return result;
    },

    expenseTemplatesByGroup() {
      return this.expenseTemplates.filter((item) => {
        let include = false;
        if (!this.expense.EXPENSE_GROUP_ID) {
          include = true;
        } else if (item.GroupId == this.expense.EXPENSE_GROUP_ID) {
          include = true;
        }
        return include;
      });
    },

    expenseTemplates() {
      let result = [];
      const idx = this.helperStore.expenseTemplates.findIndex((item) => item.agencyId == this.agencyId);
      if (idx >= 0) {
        result = this.helperStore.expenseTemplates[idx].data;
      } else if (this.agencyId) {
        getExpenseTemplates(this.agencyId);
      }
      return result;
    },

    revenue() {
      const revVar = (this.netAmount * this.expense.PROFIT_PCT) / 100;
      const revFixed = this.expense.PROFIT;
      const revHandling = this.handlingAmount;
      let result = 0;
      if (revVar) {
        result += revVar;
      }
      if (revFixed) {
        result += revFixed;
      }
      if (revHandling) {
        result += revHandling;
      }

      return result;
    },

    shortcutColumns() {
      const result = [];
      result.push({ header: 'Shortcut', field: 'Shortcut' });
      result.push({ header: 'Template', field: 'TemplateName' });
      result.push({ header: 'Group', field: 'GroupName' });
      result.push({ header: 'Cost code', field: 'CostCode' });
      return result;
    },
    templateColumns() {
      const result = [];
      result.push({ header: 'Template', field: 'TemplateName' });
      result.push({ header: 'Group', field: 'GroupName' });
      result.push({ header: 'Cost code', field: 'CostCode' });
      return result;
    },
    vatAmount() {
      let result = null;
      if (this.expense.VAT) {
        result = this.netAmount * (this.expense.VAT / 100);
      }
      return result;
    },
    vatCodes() {
      let result = [];
      const idx = this.helperStore.vatCodes.findIndex((item) => item.agencyId == this.agencyId);
      if (idx >= 0) {
        result = [...this.helperStore.vatCodes[idx].data.filter((item) => item.INACTIVE === 0)];
        if (this.expense.VAT_ID && !result.find((item) => item.VAT_ID === this.expense.VAT_ID)) {
          const found = this.helperStore.vatCodes[idx].data.find((item) => item.VAT_ID === this.expense.VAT_ID);
          if (found && typeof found === 'object' && found.VAT_CODE) {
            const selectedInactive = { ...found, VAT_CODE: `${found.VAT_CODE} (inactive)` };
            result.push(selectedInactive);
          }
        }
      } else if (this.agencyId) {
        getVatCodes(this.agencyId);
      }

      return result;
    },
  },

  watch: {
    expenseId: {
      handler(id) {
        if (id) {
          this.loadExpense(id);
        }
      },
      immediate: true,
    },
    externalUsersCanEdit: {
      immediate: true,
      handler(val) {
        if (val === false && !this.isNew && !this.applicationStore.user.internal)
          window.messageBus.$emit('showMessage', {
            text: 'The debtor has changed, you will not be able to edit this expense.',
            type: 'warning',
          });
      },
    },
    expenseType: {
      handler(newVal, oldVal) {
        if (newVal == 3) {
          if (this.budget == 0) {
            this.expense.ROE_CTM = null
          } else {
            this.expense.ROE_CTM = this.getROE(this.expense.CTM_Currency)
          }
        } else if (oldVal == 3) {
          this.expense.ROE_CTM = this.orgExpense.ROE_CTM
        }
      },
      immediate: true,
    },
    budget: {
      handler(bgt) {
        if (this.expenseType == 3) {
          if (bgt == 0) {
            this.expense.ROE_CTM = null
          } else {
            this.expense.ROE_CTM = this.getROE(this.expense.CTM_Currency)
          }
        }
      },
      immediate: true,
    }
  },

  methods: {
    lookupCode(code, list, codeField, textField) {
      return GlobalHelperFunctions.lookupCode(code, list, codeField, textField);
    },
    setFilesToUpload(files) {
      this.filesToUpload = files;
    },
    addPoNo() {
      apiGet(`/helper/PoNo/agencyId/${this.agencyId}`).then((result) => {
        if (result && result.length > 0) {
          this.expense.PO_NO = result[0].PoNo;
        }
      });
      // this.expense.PO_NO =
    },

    advanceStatusChanged(val, oldVal) {
      if ((val && val.value == 1) || (val.value == 2 && (!oldVal || val.value != oldVal.value))) {
        this.expense.AMOUNT_RECEIVED = this.expense.AMOUNT_ADVANCE;
        this.expense.RECEIVED_DATE = moment().format('YYYY-MM-DD');
      }
    },

    clearFormValues() {
      this.expense = {};
      this.orgExpense = {};
    },

    CTMCurrencyChanged(cur) {
      const roe = this.getROE(cur.value);
      if (this.expenseType == 3) {
        if (this.budget == 1) {
          this.expense.HARD_CURRENCY_ID = cur.ID;
          this.expense.ROE_CTM = roe;
        } else {
          this.expense.ROE_CTM = null;
        }
      } else {
        this.expense.HARD_CURRENCY_ID = cur.ID;
        this.expense.ROE_CTM = roe;
      }
    },

    currencyChanged(cur /* , oldCur */) {
      this.expense.CURRENCY_ID = cur.ID;

      if (!this.loading) {
        const roe = this.getROE(cur.value);
        this.expense.EXCHANGE_RATE = roe;
      }
    },

    deleteServiceExpense(serviceId, expenseId) {
      if (!serviceId) {
        // eslint-disable-next-line no-param-reassign
        serviceId = this.serviceId;
      }
      if (!expenseId) {
        // eslint-disable-next-line no-param-reassign
        expenseId = this.expenseId;
      }
      const service = {
        SER_ID: serviceId,
        SER_EXP_ID: null,
      };
      const orgService = {
        SER_ID: serviceId,
        SER_EXP_ID: expenseId,
      };
      apiPut('service', { value: service, orgValue: orgService, dynavalues: null, orgDynavalues: null }).then(
        (result) => {
          if (result.resultCategory == 1) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            apiDelete(`expense/${this.expense.ID}`).then((result) => {
              // remove progressbar
              this.isLoading = false;
              getExpenses(this.callId);
              getServices(this.callId);
              this.saving = false;
              if (result.resultCategory == 1) {
                this.orgExpense = GlobalHelperFunctions.duplicateViaJson(this.expense); // avoid stop due to change hickup
                this.$router.push({ name: 'callService', params: { serviceId, expenseId: -1 } }); // go back to services.
              }
            });
          }
        }
      );
    },

    deleteClicked() {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      if (this.serviceId) {
        // delete expense connected to a service.
        this.deleteServiceExpense(this.serviceId, this.expense.ID);
      } else {
        apiDelete(`expense/${this.expense.ID}`).then((result) => {
          // remove progressbar
          this.isLoading = false;
          getExpenses(this.callId);
          this.saving = false;
          if (result.resultCategory == 1) {
            this.orgExpense = GlobalHelperFunctions.duplicateViaJson(this.expense); // avoid stop due to change hickup
            const params = {
              callId: this.callId,
            };
            const query = {
              gridView: this.gridView,
            };
            const isCrm = this.applicationStore.isCRM;
            if (isCrm) {
              if (this.callId === '836488078') {
                query.gridView = 'moduler';
              }
              if (this.callId === '949826590') {
                query.gridView = 'Konsulent ufakturert';
              }
              if (this.callId === '949868406') {
                query.gridView = 'Tommy - politi';
              }
              if (this.callId === '949849431') {
                query.gridView = 'kurs';
              }
              if (this.callId === '949863669') {
                query.gridView = 'Refill';
              }
              if (this.callId === '949807619') {
                query.gridView = 'Konsulent ufakturert';
              }
            }

            this.$router.push({ name: 'callExpenses', params, query });
          }
        });
      }
    },

    duplicateClicked() {
      this.saveClicked(true);
    },

    expenseGroupChanged(group) {
      if (this.currentTemplate && this.currentTemplate.GroupId != group.GroupId) {
        this.expense.EXPENSE_TEMPLATE_ID = null;
      }
      if (group) {
        this.expense.EXPENSE_TYPE = group.GroupType;
      }
    },

    expenseTemplateChanged(template) {
      this.expense.EXPENSE_GROUP_ID = template.GroupId;

      // only apply template if set by user
      if (!this.templateFirstSet || this.isNew) {
        this.expense.EXPENSE_TEXT = template.ELT_DESCRIPTION;
        this.expense.ACCOUNT_CODE = template.CostCode;
        this.expense.MISC = template.ELT_EXTRA_INFO;
        this.expense.HANDLING = template.ELT_HANDLING;
        this.expense.VAT = template.ELT_VAT;
        this.expense.CODE1 = template.ELT_CODE1;
        this.expense.CODE2 = template.ELT_CODE2;
        this.expense.PROFIT_PCT = template.ELT_PROFIT_PCT;
        this.expense.PROFIT = template.ELT_PROFIT_FIXED;
        if (template.ELT_DEFAULT_PAYING_PARTY && this.applicationStore.user.internal) {
          this.expense.CLIENT_ID = this.getDefaultDebtor(template);
        }

        if (template.ELT_VAT_ID) {
          this.expense.VAT_ID = template.ELT_VAT_ID;
          const vatCode = this.vatCodes.find((item) => item.VAT_ID == template.ELT_VAT_ID);
          if (vatCode) {
            this.expense.VAT = vatCode.VAT_PERCENTAGE;
          }
        }
        if (template.ELT_SUPPLIER_ID) {
          this.expense.SUPPLIER_ID = template.ELT_SUPPLIER_ID;
        }

        if (this.isNew) {
          this.expense.QUANTITY = template.ELT_QUANTITY;
          this.expense.PRICE = template.ELT_PRICE;
          this.expense.WORK_HOURS = template.ELT_WORK_HOURS;
          if (template.ELT_GENERATE_PO_NO == 1) {
            this.addPoNo();
          }
        }

        if (template.ELT_PRICE_GROUP_ID) {
          const tarif = calculateTarif({}, template.ELT_PRICE_GROUP_ID);
          if (tarif.implemented) {
            this.expense.PRICE = tarif.price;
            this.loading = true;
            if (template.ELT_CURRENCY_CODE) this.expense.CurrencyName = template.ELT_CURRENCY_CODE;
            else this.expense.CurrencyName = tarif.currency;
            this.loading = false;
          } else {
            window.messageBus.$emit('showMessage', {
              text: 'the tarif type used on the template is yet not implemented',
              type: 'warning',
            });
          }
        } else if (template.ELT_CURRENCY_CODE) this.expense.CurrencyName = template.ELT_CURRENCY_CODE;
      } else {
        this.templateFirstSet = false;
      }
    },

    getROE(currencyCode) {
      let result = 1;
      const localCurCode = this.applicationStore.getAgencySettings(this.agencyId).LocalCurrency;
      const localCurrency = this.currencies.find((item) => item.text == localCurCode);
      if (localCurrency) {
        // eslint-disable-next-line no-prototype-builtins
        if (localCurrency.ROE.hasOwnProperty(currencyCode)) {
          result = localCurrency.ROE[currencyCode];
        }
      } else {
        result = 1;
      }
      return result;
    },

    hotlistClients(category) {
      const clients = [];
      // eslint-disable-next-line array-callback-return
      this.portcallStore.hotlist.map((client) => {
        if (client.CATEGORY == category) {
          const menuItem = {
            value: client.CLIENT_ID,
            displayName: client.C_NAME,
          };
          if (client.C_ACCOUNT_NO) {
            menuItem.displayName = `${menuItem.displayName} (${client.C_ACCOUNT_NO})`;
          }
          if (client.CLIENT_CREDIT_FLAG > 0) {
            menuItem.CLIENT_CREDIT_FLAG = client.CLIENT_CREDIT_FLAG;
            menuItem.CLIENT_CREDIT_BALANCE = client.CLIENT_CREDIT_BALANCE;
          }
          if (client.SHOW_WARNING) {
            menuItem.SHOW_WARNING = client.SHOW_WARNING;
            menuItem.WARNING = client.WARNING;
          }
          clients.push(menuItem);
        }
      });
      return clients;
    },

    getDefaultDebtor(template) {
      let payingParty = this.applicationStore.globalSettings.SGL_DEFAULT_PAYING_PARTY;
      if (template && template.ELT_DEFAULT_PAYING_PARTY) {
        payingParty = template.ELT_DEFAULT_PAYING_PARTY;
      }
      if (payingParty) {
        const debtor = this.portcallStore.hotlist.find((item) => item.CLIENT_ROLE_ID == payingParty);
        if (debtor) {
          return debtor.CLIENT_ID;
        }
      }
      return null;
    },

    loadExpense(id) {
      this.loaded = true;
      this.loading = true;
      if (this.isNew) {
        this.$refs.gatform.resetValidation();
        this.expense = {
          ID: null,
          PORTCALL_ID: this.callId,
          QUANTITY: null,
          PRICE: null,
          ROE_CTM: 1,
          PO_NO: null,
          EXPENSE_TYPE: 0,
          HISTORY: null,
          BUDGET: this.applicationStore.globalSettings.SGL_EXP_BUDGET_BY_DEFAULT,
          EXPENSE_DATE: moment().format('YYYY-MM-DD'),
          INITIALS: this.applicationStore.user.userName,
          VAT: 0,
          CLIENT_ID: this.getDefaultDebtor(this.template),
          dynaValues: [],
          CurrencyName: this.applicationStore.getAgencySettings(this.agencyId).LocalCurrency,
          MISC: null,
        };
        if (this.templateId) {
          this.expense.EXPENSE_TEMPLATE_ID = this.templateId;
        }
        if (this.serviceId) {
          this.expense.SERVICE_ID = this.serviceId;
        }

        if (!this.applicationStore.user.internal) {
          this.expense.CLIENT_ID = this.applicationStore.user.externalClientId;
        }
        apiGet(`/expense/new-values/${this.callId}`).then((data) => {
          this.$nextTick(() => {
            this.expense.REF_NUMBER = data.REF_NUMBER;
            this.expense.SORT_ORDER = data.SORT_ORDER;
            this.orgExpense = GlobalHelperFunctions.duplicateViaJson(this.expense);
            this.loading = false;
          });
        });
      } else {
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiGet(`/expense/${id}`).then((data) => {
          // remove progressbar
          this.isLoading = false;
          this.templateFirstSet = true;
          this.expense = { ...data };
          if (this.expense.APPROVED == null) {
            this.expense.APPROVED = 0;
          }
          this.orgExpense = GlobalHelperFunctions.duplicateViaJson(this.expense);
          if (this.isDuplicate) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            apiGet(`/expense/new-values/${this.callId}`).then((data) => {
              this.$nextTick(() => {
                this.orgExpense = {};
                this.expense.REF_NUMBER = data.REF_NUMBER;
                this.expense.SORT_ORDER = data.SORT_ORDER;
                this.expense.ID = null;
                // this.expense.INVOICE_ID = null;
                this.expense.INVOICE_ID = data.INVOICE_ID;
                this.expense.EXPENSE_STATUS = null;
                this.expense.BATCH_NO = null;
                this.DEBIT_EXP_ID = null;
                this.expense.EXPENSE_DATE = moment().format('YYYY-MM-DD');
                this.expense.INITIALS = this.applicationStore.user.userName;
                this.expense.PO_NO = null;
                this.loading = false;
              });
            });
          } else {
            this.$nextTick(() => {
              this.loading = false; // wait until DOM updated
            });
          }
        });
      }
    },

    getClienCreditFlagColor(value, warningElement = false) {
      let result = '';
      switch (+value) {
        case 0:
          result = 'd-none';
          break;
        case 1:
          result = warningElement ? 'red darken-1' : 'red--text text--darken-1';
          break;
        case 2:
          result = warningElement ? 'orange darken-1' : 'orange--text text--darken-1';
          break;
        case 3:
          result = warningElement ? 'green darken-1' : 'green--text text--darken-1';
          break;
        default:
          result = 'd-none';
          break;
      }
      return result;
    },

    saveClicked(duplicate) {
      const formValid = this.$refs.gatform.validate();
      if (formValid) {
        if (this.filesToUpload.length > 0) {
          this.showDialog = true;
          return;
        }
        /* if(this.expense.APPROVED == 2){
                      this.expense.APPROVED = null;
                  } */
        this.setTotals();
        GlobalHelperFunctions.setHistoryField(this.expense, 'HISTORY');
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiPut('expense', {
          value: this.expense,
          orgValue: this.orgExpense,
          dynavalues: this.expense.dynaValues,
          orgDynavalues: this.orgExpense.dynaValues,
        }).then((result) => {
          this.isLoading = false;
          if (result.resultCategory == 1) {
            // this.expense = result.payload;
            if (
              this.debtor.CLIENT_CREDIT_BALANCE &&
              this.debtor.ID &&
              (this.orgExpense.AMOUNT != this.expense.AMOUNT ||
                this.orgExpense.TOTAL_SUM != this.expense.TOTAL_SUM ||
                this.orgExpense.CURRENCY_ID != this.expense.CURRENCY_ID ||
                this.expense.CLIENT_ID != this.orgExpense.CLIENT_ID)
            ) {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              apiGet(`expense/client-credit/${this.debtor.ID}`).then((result) => {
                this.debtor.CLIENT_CREDIT_BALANCE = result.CLIENT_CREDIT_BALANCE;
                this.debtor.CLIENT_CREDIT_FLAG = result.CLIENT_CREDIT_FLAG;
              });
            }
            this.orgExpense = GlobalHelperFunctions.duplicateViaJson(this.expense);

            getExpenses(this.callId);
            // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
            this.$nextTick(() => {
              const params = {
                callId: this.callId,
              };
              if (this.serviceId) {
                getServices(this.callId);
                this.$router.push({
                  name: 'callService',
                  params: { serviceId: this.serviceId, expenseId: result.payload },
                });
                /* this.$router.push('/call/'+this.callId+'/service/'+this.serviceId); */
              } else if (duplicate) {
                params.expenseId = result.payload;
                params.duplicate = true;
                // this.$router.push('/call/'+this.callId+'/expense/'+result.payload+'/duplicate');
                this.$router.push({ name: 'callExpense', params });
              } else {
                // this.$router.push('/call/'+this.callId+'/expenses'); //go back
                this.$router.push({ name: 'callExpenses', params, query: { gridView: this.gridView } });
              }
            });
            // window.setTimeout(()=>{
            //     if(duplicate){
            //         this.$router.push('/call/'+this.callId+'/expense/'+result.payload+'/duplicate');
            //     } else{
            //         this.$router.push('/call/'+this.callId+'/expenses'); //go back
            //     }
            // },400)
          }
        });
      }
    },

    setTotals() {
      this.expense.AMOUNT = this.expense.PRICE * this.expense.QUANTITY;
      this.expense.TOTAL_SUM = this.netAmount + this.vatAmount;
      if (this.expenseType == 3) {
        this.expense.AMOUNT_LOCAL_CURRENCY =
          Math.round(this.expense.AMOUNT_FOREIGN_CURRENCY * this.expense.ROE_CTM * 100) / 100;
        this.expense.AMOUNT_AS_TEXT = this.CTMAmountAsText;
      }
    },
  },
});
</script>

<style lang="scss">
// Fjernet horisontal scrollbar på shortcut select. Mulig det finnes en bedre løsning som kan implementeres senere.
#list-56>div>div>.v-data-table__wrapper {
  overflow-x: hidden;
}
</style>

<style lang="scss" scoped></style>
